import React from 'react';
import { Tag, Typography } from 'antd';

const { Title } = Typography;

const severityColors: any = {
  critical: 'purple',
  high: 'red',
  medium: 'orange',
  low: 'green',
  information: 'blue'
};

const defaultFilters: any = {
  severity: {},
  status: {},
  risk_score: [0, 100],
  exploit: false,
  malware: false,
  zero_day: false,
  unpatched: false
};

const ActiveFilters = ({ filters }: any) => {
  const activeFilters = filters || defaultFilters;

  return (
    <div>
      <Title level={5}>Active Filters:</Title>
      <ul className="list-none p-0">
        {Object.keys(activeFilters).map(key => {
          if (key === 'risk_score') {
            return (
              <li
                key={key}
                className="text-sm text-gray-700 mb-2"
              >
                <strong
                  className="capitalize mr-2"
                >{key.replace('_', ' ')}:</strong>
                <Tag bordered={false} color="processing">
                  {`${activeFilters[key][0]} - ${activeFilters[key][1]}`}
                </Tag>
              </li>
            );
          } else if (typeof activeFilters[key] === 'object') {
            const subKeys = Object.keys(activeFilters[key]).filter(subKey => activeFilters[key][subKey]);
            if (Array.isArray(activeFilters[key] )) {
              if(activeFilters[key].length === 0) return;
              return (
                <li key={key} className="text-sm text-gray-700 mb-2">
                  <strong 
                    className="capitalize mr-2"
                  >{key}:</strong>
                  {activeFilters[key].map((item: any, index: number) => (
                    <Tag 
                      key={index} 
                      bordered={false} 
                      color={severityColors[item] || 'processing'}
                    >
                      {item}
                    </Tag>
                  ))}
                </li>
              );
            }
            if (subKeys.length > 0) {
              return (
                <li
                  key={key}
                  className="text-sm text-gray-700 mb-2"
                >
                  <strong
                    className="capitalize mr-2"
                  >{key}:</strong>
                  {subKeys.map(subKey => (
                    <Tag
                      key={subKey}
                      bordered={false}
                      color={severityColors[subKey] || 'processing'}
                    >
                      {subKey}
                    </Tag>
                  ))}
                </li>
              );
            }
          } else if (activeFilters[key]) {
            return (
              <li
                key={key}
                className="text-sm text-gray-700 mb-2"
              >
                <Tag bordered={false} color="processing">
                  {key}
                </Tag>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

export default ActiveFilters;

import { Input } from '@material-tailwind/react';
import { InputFieldProps } from './type';
import { useField } from 'formik';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import {
  Divider,
  Typography,
  Alert,
  Button as AntButton,
  Input as AntInput,
  Form as AntdForm
} from 'antd';

const InputField: React.FC<InputFieldProps> = ({
  label,
  type = 'text',
  placeholder,
  disabled = false,
  hidden = false,
  size,
  iconRight,
  className = '',
  widthInput,
  name,
  id,
  required = false
}) => {
  const [field, meta] = useField(name);
  const showError = meta.touched && meta.error;

  if (hidden) return null;

  return (
    <div className={`${className}`}>
      <AntdForm.Item
        layout="vertical"
        required={required}
        label={label}
        validateStatus={showError ? 'error' : ''}
        help={showError ? meta.error : ''}
      >
        <AntInput
          size={size}
          id={id}
          type={type}
          typeof={type}
          name={name}
          placeholder={placeholder}
          onChange={field.onChange}
          value={field.value}
          disabled={disabled}
        />
      </AntdForm.Item>
    </div>
  );
};

export default InputField;

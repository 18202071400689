import { Link } from 'react-router-dom';

const SignupText = () => (
  <div className="mt-6 text-center">
    <p>
        Don’t have any account?{' '}
      <Link to="/auth/signup" className="text-primary">Sign Up</Link>
    </p>
  </div>
);

export default SignupText;
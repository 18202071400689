import axios from 'axios';
import { AUTH_BASE_URL } from '../config/serverApiConfig';
import { notification } from 'antd';
import { setupCache } from 'axios-cache-interceptor';

// Function to handle login redirection
function redirectLogin() {
  notification.info({
    message: 'Session Expired',
    description: 'Your session has expired. Please log in again to continue.'
  });
  window.localStorage.removeItem('auth');
  window.localStorage.removeItem('isLogout');
  
  const currentPath = window.location.pathname;
  window.localStorage.setItem('lastVisitedPath', currentPath);
  if (!window.location.href.includes('/login')) {
    window.location.href = '/login';
  }
}

// Function to handle the refresh token process
async function refreshAccessToken() {
  const user = localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : null;

  if (!tmpAuth || !tmpAuth.refresh_token) {
    console.error('No refresh token available');
    redirectLogin();
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post(`${AUTH_BASE_URL}refresh_token`, {
      refresh_token: tmpAuth.refresh_token
    }, {
      headers: {
        'Authorization': `Bearer ${tmpAuth.refresh_token}`
      }
    });

    const { access_token, refresh_token } = response.data;

    const updatedTokens = {
      ...tmpAuth,
      access_token,
      refresh_token: refresh_token ? refresh_token : tmpAuth.refresh_token
    };
    localStorage.setItem('auth', JSON.stringify({ current: updatedTokens }));

    return access_token;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    redirectLogin();
    throw error;
  }
}

// Create and configure axios instance
export const api = () => {
  const user = localStorage.getItem('auth');
  const tmpAuth = user ? JSON.parse(user).current : {};

  const instance = axios.create({
    headers: {
      'Authorization': `Bearer ${tmpAuth.access_token}`
    }
  });

  // Setup axios cache interceptor
  const cache = setupCache(instance,{
  });

  // Interceptor to handle token refresh
  cache.interceptors.response.use((response: any) => response, async (error: any) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;
        return cache(originalRequest);
      } catch (refreshError) {
        redirectLogin();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  });

  return instance;
};

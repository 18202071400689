import React from 'react';
import { Select } from 'antd';
import { SelectFieldProps } from './type';
import { useField } from 'formik';

const { Option } = Select;

export function SelectFiled({
  options,
  label,
  placeholder,
  hidden = false,
  name,
  required = false,
  onChange
}: SelectFieldProps) {
  const [field, meta] = useField(name);

  if (hidden) return null;

  return (
    <div className="">
      <div 
        className="text-start mb-1 block text-black dark:text-white font-bold"
      >
        {label}
        {required && <span className="text-red-500">*</span>}
      </div>
      <Select
        {...field}
        id={name}
        // name={name}
        // fieldNames={name}
        placeholder={placeholder}
        value={field.value}
        onChange={(value) => {
          const event = {
            target: {
              name: field.name,
              value
            }
          };
          field.onChange(event);
          if (onChange) onChange(value);
        }}
        className={`w-full ${meta.touched && meta.error ? 'border-red-500' : ''}`}
      >
        {options.map(option => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <div className="text-red-500 mt-1">{meta.error}</div>
      )}
    </div>
  );
}

import { Card } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartDoubleState {
  series: {
    name: string;

    data: number[];
  }[];
}

interface BAR_CHART_PROPS {
  seriesDefault?: number[];
  height?: number;
  title?: string;
  categories?: string[];
  series?: any[];
  extra?: React.ReactNode;
  distributed?: boolean;
  dataLabels?: boolean;
}

const BarChartDouble: React.FC<BAR_CHART_PROPS> = ({
  distributed = false,
  title = 'Most vulnerable assets',
  categories = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  dataLabels = false,
  series = [{
    name: 'Discovered date',
    data: [44, 35, 21, 98, 17, 15, 91, 110, 215, 90, 280, 50]
  }, {
    name: 'Closed date',
    data: [18, 25, 201, 298, 17, 15, 91, 10, 215, 390, 28, 10]
  }],
  extra

}) => {
  const [state, setState] = useState<BarChartDoubleState>({
    series
  });

  const options: ApexOptions = {
    colors: [
      '#6F48EC', '#EF4444', '#F59E0B', '#10B981', '#3B82F6'
    ],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 350,
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        distributed,
        borderRadius: 4,
        dataLabels: {
          total: {
            enabled: true,
            offsetY: 6,
            offsetX: 10,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: dataLabels
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (val) {
          return val;
        }
      }
    },
    // yaxis: {
    //   title: {
    //     text: undefined
    //   }
    // },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'inter',
      markers: {
        radius: 99
      }
    },
    // yaxis: {
    //   title: false,
    // },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    fill: {
      opacity: 10
    }
  };

  return <div id="BarChartDouble" className="-ml-5">
    <ReactApexChart
      options={options}
      series={state.series}
      type="bar"
      height={350}
    />
  </div>;
};

export default BarChartDouble;

import {
  BugAntIcon,
  DocumentChartBarIcon,
  FunnelIcon,
  ServerStackIcon
} from '@heroicons/react/24/outline';
import {
  Card,
  Select,
  Table,
  DatePicker,
  Spin,
  Popover,
  Button,
  Badge,
  Slider,
  Collapse,
  CollapseProps,
  Segmented,
  TabsProps,
  Divider
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { TopAssetColumns, TopVulsColumns } from './TableConfig';
import PieChart from '../../UI/chart/PieChart';
import BarChart from '../../UI/chart/BarChart';
import BarChartDouble from '../../UI/chart/BarChartDouble';
import dashboardService from '../../../service/dashboard.service';
import { DASHBOARD_PROPS } from './type';
import { IconButton } from '@material-tailwind/react';
import { Formik, Form } from 'formik';
import CheckBoxButton from '../../UI/checkbox/CheckBoxButton';
import CheckBoxField from '../../UI/checkbox/CheckBoxField';
import { AVAILABILITY, VULNERABILITY_STATUS } from '../../../config/enum';
import { Typography } from 'antd';
import ActiveFilters from './ActiveFilter';
import CardStat from '../../UI/card/CardStat.ui';
import projectService from '../../../service/project.service';
import {
  complianceData,
  criticalityOptions,
  dataSensitivityData,
  networkExposureData
} from '../asset/detail/tab/RiskAttributesTab';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const DashboardGeneral = () => {

  const [data, setdata] = useState<DASHBOARD_PROPS>();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await dashboardService.getGlobalDashboard();
      let result: DASHBOARD_PROPS = data.data.result;
      result.vulnerability_by_severity_pie = {
        data: result.vulnerability_by_severity?.map((vuln) => vuln.value),
        labels: result.vulnerability_by_severity?.map((vuln) => vuln.name)
      };
      setdata(result);
      setIsLoading(false);
    };
    getData();
  }, []);

  const navigate = useNavigate();

  const formikRef = useRef<any>(null);
  const formikRef2 = useRef<any>(null);
  const formikRef3 = useRef<any>(null);
  const contentListNoTitle: Record<string, React.ReactNode> = {
    Pie: <PieChart
      seriesDefault={data?.vulnerability_by_severity_pie?.data}
      label={data?.vulnerability_by_severity_pie?.labels}
      colors={['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6']}
    />,
    Bar: <div>
      <BarChartDouble
        dataLabels
        distributed
        title='Most vulnerable projects'
        categories={data?.vulnerability_by_severity_pie?.labels}
        series={[{
          data: data?.vulnerability_by_severity_pie?.data
        }]}
      />
    </div>
  };
  const contentListNoTitle2: Record<string, React.ReactNode> = {
    Pie: <PieChart
      seriesDefault={[data?.host_risk_distribution?.critical || 0 , data?.host_risk_distribution?.high || 0, data?.host_risk_distribution?.medium || 0, data?.host_risk_distribution?.low || 0]}
      label={['Critical', 'High', 'Medium', 'Low']}
      colors={['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6']}
    />,
    Bar: <div>
      <BarChartDouble
        dataLabels
        distributed
        title='Most vulnerable projects'
        categories={['Critical', 'High', 'Medium', 'Low']}
        series={[{
          data: [data?.host_risk_distribution?.critical || 0 , data?.host_risk_distribution?.high || 0, data?.host_risk_distribution?.medium || 0, data?.host_risk_distribution?.low || 0]
        }]}
      />
    </div>
  };

  const contentListRiskTable: Record<string, React.ReactNode> = {
    vulns: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopVulsColumns}
      dataSource={data?.row_top_risk || []}
      size="small"
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
      onRow={(row) => ({
        onClick: () => navigate(`/vulnerability/${row.id}`)
      })}
    />,
    assets: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopAssetColumns}
      dataSource={data?.row_top_risk_assets || []}
      size="small"
      onRow={(row) => ({
        onClick: () => navigate(`/asset/${row.id}`)
      })}
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
    />,
    projects: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopVulsColumns}
      dataSource={data?.most_vulnerable_projects || []}
      size="small"
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
      onRow={(row) => ({
        onClick: () => navigate(`/project/${row.id}`)
      })}
    />
  };
  const [activeTabKey, setActiveTabKey] = useState<string>('Pie');
  const [activeTabKey2, setActiveTabKey2] = useState<string>('Pie');
  const [activeTabKeyRisk, setActiveTabKeyRisk] = useState<string>('vulns');
  const severities = [{
    name: 'critical',
    color: 'bg-purple-500',
    label: 'C'
  }, {
    name: 'high',
    color: 'bg-red-500',
    label: 'H'
  }, {
    name: 'medium',
    color: 'bg-yellow-500 text-black',
    label: 'M'
  }, {
    name: 'low',
    color: 'bg-green-500',
    label: 'L'
  }, {
    name: 'information',
    color: 'bg-blue-500',
    label: 'I'
  }];
  const items: CollapseProps['items'] = [{
    key: '2',
    label: 'Status',
    children: <div>   {VULNERABILITY_STATUS.map(v => (
      <CheckBoxField
        key={v.value}
        name={'status.' + v.value}
        label={v.label}
      />
    ))}</div>
  }];

  const initialValues = {
    severity: {
      high: false,
      medium: false,
      critical: false,
      low: false,
      information: false
    },
    status: {
      open: false,
      detected: false,
      mitigated: false,
      analysed: false,
      solved: false,
      reopened: false,
      risk_accepted: false,
      false_positive: false,
      fixed: false
    },
    sources: [],
    criticality: [],
    data_sensitivity: [],
    network_exposure: [],
    compliance: [],
    availability: [],
    risk_score: [0, 100],
    exploit: false,
    malware: false,
    zero_day: false,
    unpatched: false
  };

  const [projects, setprojects] = useState([]);
  const [loadingPorject, setloadingPorject] = useState(false);
  useEffect(() => {
    const getProjects = async () => {
      setloadingPorject(true);
      const projects = await projectService.getListName();
      setprojects(projects.projects.map((v: any) => ({
        label: v.name + ` (${v.vulns_count})`,
        value: v.name
      })));
      setloadingPorject(false);
    };
    getProjects();
  }, []);

  return <Spin tip="Loading" size="small" spinning={isLoading}>
    <div className="flex flex-col">
      <h2
        className="text-title-md font-semibold text-black dark:text-white mb-5"
      >Dashboard</h2>
      <div className="">
        <div className='flex gap-4'>
          <Card
            type='inner'
            className='w-1/4 text-center flex items-center justify-center'
            bodyStyle={{
              padding: '0px 0',
              margin: '0px'
            }}
            style={{
              padding: '0px',
              margin: '0px'
            }}
            size='small'
          >
            <div
              className="flex justify-center items-center flex-col  lg:flex-row max-h-[100px]"
            >
              <Title level={5}>Global Risk<br /> Score</Title>
              <GaugeChart
                className='h-[10px] w-[180px] flex justify-center items-center'
                style={{
                  height: '10%'
                }}
                id="gauge-chart3"
                nrOfLevels={30}
                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                arcWidth={0.3}
                percent={data?.global_risk_score ? data?.global_risk_score / 100 : 0}
                textColor='#000'
                needleColor='#d0d0d0'
                arcPadding={0.02}
              />
            </div>
          </Card>
          <div
            className="grid gap-5 sm:grid-cols-3 lg:grid-cols-3 max-h-[100px]"
          >

            <CardStat
              name="Projects"
              total={data?.total_project || 0}
              icon={<DocumentChartBarIcon
                className="h-6 w-6 text-indigo-500"
              />}
              isLoading={isLoading}
            />
            <CardStat
              name="Total of vulnerabilities"
              total={data?.total_vuln || 0}
              icon={<BugAntIcon className="h-6 w-6 text-red-500" />}
              isLoading={isLoading}
              color='border-l-red-500'
            />
            <CardStat
              name="Total of assets"
              total={data?.total_asset || 0}
              icon={<ServerStackIcon className="h-6 w-6 text-purple-500" />}
              isLoading={isLoading}
              color='border-l-purple-500'
            />
          </div>
        </div>
        <div className="flex gap-4 mt-4 ">
          <div className="w-full xl:w-2/3">
            <Card
              // size='small'
              className='p-0'
              bodyStyle={{ padding: '10px 10px' }}
              type='inner'
              activeTabKey={activeTabKeyRisk}
              onTabChange={(key) => setActiveTabKeyRisk(key)}
              title={
                <div>
                  <Title level={5}>Risk Score</Title>
                </div>
              }
              tabList={[{
                key: 'vulns',
                tab: 'Vulnerabilities'
              }, {
                key: 'assets',
                tab: 'Assets'
              }, {
                key: 'projects',
                tab: 'Projects'
              }]}
            >
              {contentListRiskTable[activeTabKeyRisk]}
            </Card>
          </div>
          <div className="w-full xl:w-1/3 h-full">
            <Card
              type='inner'
              title="Vulnerability by severity"
              className='p-0'
              extra={
                <div>
                  <Segmented
                    defaultValue="Pie"
                    value={activeTabKey}
                    style={{ marginBottom: 8 }}
                    onChange={(value: any) => setActiveTabKey(value)}
                    options={['Pie', 'Bar']}
                  />
                  <Popover
                    placement='bottom'
                    arrow={false}
                    content={<div>
                      <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={async (values, actions) => {
                          setOpen(false);
                        }}
                      >
                        {({ setFieldValue, values }) => <Form>
                          <div
                            className="flex justify-between items-center py-2"
                          >
                            <div>Quick filter </div>
                            <Button
                              type='link'
                              onClick={() => {
                                formikRef.current.resetForm();
                                formikRef.current.setSubmitting(true);
                              }}
                            >Reset</Button>
                          </div>

                          <div className="w-[300px] h-[500px] overflow-auto">
                            <div className="px-5">
                              Severity
                              <div className="p-4 flex flex-wrap gap-2">
                                {severities.map(v => (
                                  <CheckBoxButton
                                    name={'severity.' + v.name}
                                    label={v.label}
                                    bgColor={v.color}
                                    className='w-[36px] text-center'
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="px-5 my-5 flex flex-col">
                              Sources
                              <Select
                                onChange={(v) => setFieldValue('sources', v)}
                                defaultValue={values.sources}
                                value={values.sources}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select source..."
                                options={[{
                                  label: 'Nessus ',
                                  value: 'nessus'
                                }, {
                                  label: 'Burp',
                                  value: 'burp'
                                }, {
                                  label: 'Nexpose',
                                  value: 'nexpose'
                                }, {
                                  label: 'Manual',
                                  value: 'manual'
                                }]}
                              />
                            </div>
                            <div className="px-5 flex flex-col my-5">
                              Risk Score
                              <Slider
                                className='m-4'
                                range
                                min={0}
                                max={100}
                                value={values.risk_score}
                                defaultValue={[0, 100]}
                                onChange={(v) => setFieldValue('risk_score', v)}
                                marks={{
                                  0: '0',
                                  80: {
                                    style: {
                                      color: 'orange'
                                    },
                                    label: <strong>80</strong>
                                  },
                                  100: {
                                    style: {
                                      color: 'red'
                                    },
                                    label: <strong>100</strong>
                                  }
                                }}
                              />
                            </div>
                            <Collapse
                              items={items}
                              bordered={false}
                              defaultActiveKey={[]}
                            />

                            <div className="flex flex-col">
                              <CheckBoxField
                                name={'exploit'}
                                label={'Total exploit'}
                              />
                              <CheckBoxField
                                name={'malware'}
                                label={'Malware'}
                              />
                              <CheckBoxField
                                name={'zero_day'}
                                label={'Zero day'}
                              />
                              <CheckBoxField
                                name={'unpatched'}
                                label={'Unpatched'}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <Button
                              type="primary"
                              htmlType='submit'
                              onClick={() => {}}
                            >Filter</Button>
                          </div>
                        </Form>}
                      </Formik>
                      {/* {Form} */}
                    </div>}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenChange}
                  >
                    <IconButton
                      size="md"
                      variant="text"
                      className="rounded-full"
                    >
                      <Badge
                        dot={(formikRef.current?.dirty)}
                      >
                        <FunnelIcon
                          strokeWidth={2}
                          className="h-5 w-5 text-body"
                        />
                      </Badge>

                    </IconButton>
                  </Popover>
                </div>
              }
            >
              {contentListNoTitle[activeTabKey]}
              <Divider />
              <ActiveFilters filters={formikRef.current?.values} />
            </Card>
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-full xl:w-1/3">
            <Card
              type="inner"
              title='Vulnerability by OS'
              extra={
                <div>
                  <Popover
                    placement='bottom'
                    arrow={false}
                    content={<div>
                      <Formik
                        innerRef={formikRef2}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={async (values, actions) => {
                          setOpen2(false);
                        }}
                      >
                        {({ setFieldValue, values }) => <Form>
                          <div
                            className="flex justify-between items-center py-2"
                          >
                            <div>Quick filter </div>
                            <Button
                              type='link'
                              onClick={() => {
                                formikRef2.current.resetForm();
                                formikRef2.current.setSubmitting(true);
                              }}
                            >Reset</Button>
                          </div>

                          <div className="w-[300px] h-[200px] overflow-auto">
                            <div className="px-5">
                              Severity
                              <div className="p-4 flex flex-wrap gap-2">
                                {severities.map(v => (
                                  <CheckBoxButton
                                    name={'severity.' + v.name}
                                    label={v.label}
                                    bgColor={v.color}
                                    className='w-[36px] text-center'
                                  />
                                ))}
                              </div>
                            </div>

                            <div className="px-5 flex flex-col my-5">
                              Risk Score
                              <Slider
                                className='m-4'
                                range
                                min={0}
                                max={100}
                                value={values.risk_score}
                                defaultValue={[0, 100]}
                                onChange={(v) => setFieldValue('risk_score', v)}
                                marks={{
                                  0: '0',
                                  80: {
                                    style: {
                                      color: 'orange'
                                    },
                                    label: <strong>80</strong>
                                  },
                                  100: {
                                    style: {
                                      color: 'red'
                                    },
                                    label: <strong>100</strong>
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <Button
                              type="primary"
                              htmlType='submit'
                              onClick={() => {}}
                            >Filter</Button>
                          </div>
                        </Form>}
                      </Formik>
                      {/* {Form} */}
                    </div>}
                    trigger="click"
                    open={open2}
                    onOpenChange={(open) => setOpen2(open)}
                  >
                    <IconButton
                      size="md"
                      variant="text"
                      className="rounded-full"
                    >
                      <Badge
                        dot={(formikRef2.current?.dirty)}
                      >
                        <FunnelIcon
                          strokeWidth={2}
                          className="h-5 w-5 text-body"
                        />
                      </Badge>

                    </IconButton>
                  </Popover>
                </div>
              }
            >
              <PieChart
                seriesDefault={[data?.os_count?.windows || 0, data?.os_count?.linux || 0, data?.os_count.mac || 0, data?.os_count?.other || 0]}
                label={['Windows', 'Linux', 'Mac', 'Other']}
                colors={['#1E90FF', '#FF4500', '#32CD32', '#FFD700']}
                type='pie'
                showPercentage={true}
                showDataLabels
              />
              <Divider />
              <ActiveFilters filters={formikRef2.current?.values} />
            </Card>
          </div>

          <div className="w-full xl:w-1/3">
            <Card
              type="inner"
              title='Project by risk score'
            >    
              <PieChart
                seriesDefault={[data?.project_risk_distribution?.critical || 0 , data?.project_risk_distribution?.high || 0, data?.project_risk_distribution?.medium || 0, data?.project_risk_distribution?.low || 0]}
                label={['Critical', 'High', 'Medium', 'Low']}
                colors={['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6']}
                type='donut'
                showPercentage={true}
                showDataLabels={false}
              />
            </Card>
          </div>
          <div className="w-full xl:w-1/3 h-full">
            <Card
              type='inner'
              title="Asset by risk"
              className='p-0'
              extra={
                <div>
                  <Segmented
                    defaultValue="Pie"
                    value={activeTabKey2}
                    style={{ marginBottom: 8 }}
                    onChange={(value: any) => setActiveTabKey2(value)}
                    options={['Pie', 'Bar']}
                  />
                  <Popover
                    placement='bottom'
                    arrow={false}
                    content={<div>
                      <Formik
                        innerRef={formikRef3}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={async (values, actions) => {
                          setOpen3(false);
                        }}
                      >
                        {({ setFieldValue, values }) => <Form>
                          <div
                            className="flex justify-between items-center py-2"
                          >
                            <div>Quick filter </div>
                            <Button
                              type='link'
                              onClick={() => {
                                formikRef3.current.resetForm();
                                formikRef3.current.setSubmitting(true);
                              }}
                            >Reset</Button>
                          </div>

                          <div className="w-[300px] h-[500px] overflow-auto">
                            <div className="px-5">
                              Risk
                              <div className="p-4 flex flex-wrap gap-2">
                                {severities.map(v => (
                                  <CheckBoxButton
                                    name={'severity.' + v.name}
                                    label={v.label}
                                    bgColor={v.color}
                                    className='w-[36px] text-center'
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="px-5 flex flex-col my-5">
                              AVS
                              <Slider
                                className='m-4'
                                range
                                min={0}
                                max={100}
                                value={values.risk_score}
                                defaultValue={[0, 100]}
                                onChange={(v) => setFieldValue('risk_score', v)}
                                marks={{
                                  0: '0',
                                  80: {
                                    style: {
                                      color: 'orange'
                                    },
                                    label: <strong>80</strong>
                                  },
                                  100: {
                                    style: {
                                      color: 'red'
                                    },
                                    label: <strong>100</strong>
                                  }
                                }}
                              />
                            </div>
                            <strong>Risk Attributes</strong>
                            <div className="px-5 my-5 flex flex-col">
                              Criticality
                              <Select
                                onChange={(v) => setFieldValue('criticality', v)}
                                defaultValue={values.criticality}
                                value={values.criticality}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select criticality ..."
                                options={criticalityOptions}
                              />
                            </div>
                            <div className="px-5 my-5 flex flex-col">
                              Data Sensitivity
                              <Select
                                onChange={(v) => setFieldValue('data_sensitivity', v)}
                                defaultValue={values.data_sensitivity}
                                value={values.data_sensitivity}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select data sensitivity ..."
                                options={dataSensitivityData}
                              />
                            </div>
                            <div className="px-5 my-5 flex flex-col">
                              Network exposure
                              <Select
                                onChange={(v) => setFieldValue('network_exposure', v)}
                                defaultValue={values.network_exposure}
                                value={values.network_exposure}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select network exposure ..."
                                options={networkExposureData}
                              />
                            </div>
                            <div className="px-5 my-5 flex flex-col">
                              Compliance
                              <Select
                                onChange={(v) => setFieldValue('compliance', v)}
                                defaultValue={values.compliance}
                                value={values.compliance}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select compliance ..."
                                options={complianceData}
                              />
                            </div>
                            <div className="px-5 my-5 flex flex-col">
                              Availability
                              <Select
                                onChange={(v) => setFieldValue('availability', v)}
                                defaultValue={values.availability}
                                value={values.availability}
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select availability ..."
                                options={AVAILABILITY}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end">
                            <Button
                              type="primary"
                              htmlType='submit'
                              onClick={() => {}}
                            >Filter</Button>
                          </div>
                        </Form>}
                      </Formik>
                      {/* {Form} */}
                    </div>}
                    trigger="click"
                    open={open3}
                    onOpenChange={(v) => setOpen3(v)}
                  >
                    <IconButton
                      size="md"
                      variant="text"
                      className="rounded-full"
                    >
                      <Badge
                        dot={(formikRef3.current?.dirty)}
                      >
                        <FunnelIcon
                          strokeWidth={2}
                          className="h-5 w-5 text-body"
                        />
                      </Badge>

                    </IconButton>
                  </Popover>
                </div>
              }
            >
              {contentListNoTitle2[activeTabKey2]}
              
              <Divider />
              <ActiveFilters filters={formikRef3.current?.values} />
            </Card>
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-full xl:w-2/3 cursor-not-allowed relative">
            <div
              className="absolute inset-0rounded-lg "
            ></div>
            <div className=" w-full -z-10">
              <BarChart
                title='Most vulnerable projects'
                extra={
                  <div className='gap-4 flex'>
                    <Select
                      // onChange={(v) => setFieldValue('projects', v)}
                      // defaultValue={filter.projects}
                      // value={values.projects}
                      mode="multiple"
                      loading={loadingPorject}
                      allowClear
                      style={{ minWidth: '200px', maxWidth: '500px' }}
                      placeholder="Please select projects..."
                      options={projects}
                    />
                  </div>
                }
              />
            </div>
          </div>
          <div className="w-full xl:w-1/3 cursor-not-allowed relative">
            <div className=" w-full -z-10">
              <Card
                title="SLA"
                type='inner'
                extra={
                  <div className='gap-4 flex'>
                    <DatePicker picker={'year'} />
                  </div>
                }
              >
                <BarChartDouble />
              </Card>
            </div>
          </div>
        </div>
      </div>

    </div>
  </Spin>;
};

export default DashboardGeneral;

import React from 'react';
import { CardZeusProps } from './type';
import { Button, Card, CardProps } from 'antd';

const CardZeus: React.FC<CardProps> = ({ children, className, ...props }) => (

  <Card 
    className='min-h-[80vh]' 
    {...props}
  >{children}</Card>
);

export default CardZeus;
export const RESET_STATE = 'USER_RESET_STATE';
export const CURRENT_ITEM = 'USER_CURRENT_ITEM';

export const REQUEST_LOADING = 'USER_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'USER_REQUEST_FAILED';

export const CURRENT_ACTION = 'USER_CURRENT_ACTION';
export const RESET_ACTION = 'USER_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_PAGINATION = 'USER_UPDATE_PAGINATION';

export const RESET_LIST = 'USER_RESET_LIST';

export const UPDATE_FILTER = 'USER_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';
import {
  generatePieChartImageURL
} from '../../components/features/report/create/PieChartToImage';
import { getVulnerabilities } from '../../service/vulnerability.service';
import * as actionTypes from './types';
// import { request } from '@/request';

export const report = {
  resetState:
    (props = {}) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_STATE
        });
      },
  resetAction:
    ({ actionType }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_ACTION,
          keyState: actionType,
          payload: null
        });
      },
  currentItem:
    ({ data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: { ...data }
        });
      },
  currentAction:
    ({ actionType, data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ACTION,
          keyState: actionType,
          payload: { ...data }
        });
      },
  updateReportField:
    (key, value) =>
      async (dispatch) => {
        if (key === 'project') {
          const response = await getVulnerabilities({
            page: 1,
            items: 1000,
            sorting: null,
            globalFilter: null,
            filter: {
              // assets: [],
              // severity: {},
              // duplicates: false,
              // tags: [],
              // riskScore: [],
              projects: [value]
              // status: {},
              // exploit: false,
              // malware: false,
              // zero_day: false,
              // unpatched: false
            },
            report: true
          });
          const ipSet = new Set();
          response.data.vulns.forEach((vuln) => {
            ipSet.add(vuln.asset.ip);
          });

          // Map each IP from the set into an object with an 'ip' property
          const listAsset = Array.from(ipSet).map(ip => ({ ip }));

          const severityCounts = {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0,
            informational: 0
          };

          // Iterate over the vulnerabilities and increment the count for each severity level
          response.data.vulns.forEach((vuln) => {
            const severity = vuln.severity;
            if (severityCounts.hasOwnProperty(severity)) {
              severityCounts[severity]++;
            }
          });

          const severity = await generatePieChartImageURL(
            [severityCounts?.critical, severityCounts?.high, severityCounts?.medium, severityCounts?.low, severityCounts?.informational],
            ['Critical', 'High', 'Medium', 'Low', 'Informational'],
            ['#9C27B0', '#D32F2F', '#FFEB3B', '#66BB6A', '#29B6F6'],
            'By Severity'
          );
          const status = await generatePieChartImageURL([
            10, 10, 10, 10, 10, 10, 10, 10, 10
          ], ['open', 'detected', 'analysed', 'mitigated', 'fixed', 'solved', 'reopened', 'false_positive', 'risk_accepted'],
          ['#000000', '#E53935', '#FB8C00', '#43A047', '#1E88E5', '#8E24AA', '#F4511E', '#00695C', '#3949AB'],
          'By Status'
          );

          const easeOfResolution = await generatePieChartImageURL([
            10, 10, 10, 10, 10
          ], ['Trivial', 'Simple', 'Moderate', 'Difficult', 'Infeasible'],
          ['#9E9E9E', '#81C784', '#4FC3F7', '#BA68C8', '#E53935'],
          'Ease of Resolution'
          );

          dispatch({
            type: actionTypes.UPDATE_REPORT_DATA,
            payload: {
              vulnerabilities: response.data.vulns,
              asset_list: listAsset,
              severityCounts,
              charts: {
                severity,
                status,
                easeOfResolution
              },
              project: value
            }
          });
        } else {
          dispatch({
            type: actionTypes.UPDATE_REPORT_DATA,
            payload: { [key]: value }
          });
        }
      }
};

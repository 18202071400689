import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton
} from '@material-tailwind/react';
import CSVImage from '../../../../assets/image/csv-icon.png';
import EXCElImage from '../../../../assets/image/excel-icon.png';

interface ExportProps {
    onClickExport: (format: 'csv' | 'excel') => void; // Ensure the function expects either 'csv' or 'excel' as an argument
}
const Export: React.FC<ExportProps> = ({ onClickExport }) => (
  <Menu>
    <MenuHandler>
      <IconButton
        size="md"
        variant="text"
        className="rounded-full"
        // onClick={() => exportAction(table.getSelectedRowModel().flatRows.map((row) => row.id))}
      >
        <ArrowDownTrayIcon
          strokeWidth={2}
          className="h-5 w-5"
        />
      </IconButton>
    </MenuHandler>
    <MenuList>
      <MenuItem 
        className="flex items-center gap-2" 
        onClick={() => onClickExport('csv')}
      >
        <img
          src={CSVImage}
          alt=""
          height={20}
          width={20}
        />

        <div className="flex justify-center items-center">
                    CSV
        </div>
      </MenuItem>
      <MenuItem
        onClick={() => onClickExport('excel')}
        className="flex items-center gap-2"
      >
        <img
          src={EXCElImage}
          alt=""
          height={15}
          width={20}
        />

        <div className="flex justify-center items-center">
                    Excel
        </div>
      </MenuItem>
    </MenuList>
  </Menu>
);

export default Export;
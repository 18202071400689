import { useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../image/Logo';
import { useTranslation } from 'react-i18next';
import {
  BugAntIcon,
  RectangleGroupIcon,
  HomeIcon,
  ServerStackIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  LinkIcon

} from '@heroicons/react/24/outline';
import IconButtonSideBar from './IconButtonSideBar';
import { ConfigProvider, Menu } from 'antd';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
}

const SidebarCustom = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const { t } = useTranslation('sidebar');
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const menuItems = [
    {
      name: t('dashboard'),
      link: '/',
      icon: HomeIcon
    }, {
      name: t('project'),
      link: '/project',
      icon: RectangleGroupIcon
    }, {
      name: t('actionPlan'),
      link: '/action-plan',
      icon: DocumentTextIcon
    }, {
      name: t('vulnerability'),
      link: '/vulnerability',
      icon: BugAntIcon
    }, {
      name: t('asset'),
      link: '/asset',
      icon: ServerStackIcon
    }, {
      name: t('Report'),
      link: '/report',
      icon: ClipboardDocumentListIcon
    }, {
      name: t('Users'),
      link: '/user',
      icon: UserGroupIcon
    }, {
      name: t('Connectors'),
      link: '/connectors',
      icon: LinkIcon
    }
  ];

  const menuItemsNew = [
    {
      key: '1',
      icon: <HomeIcon />,
      label: t('dashboard'),
      link: '/'
    }, {
      key: '2',
      icon: <RectangleGroupIcon />,
      label: t('project'),
      link: '/project'
    }, {
      key: '3',
      icon: <DocumentTextIcon />,
      label: t('actionPlan'),
      link: '/action-plan'
    }, {
      key: '4',
      icon: <BugAntIcon />,
      label: t('vulnerability'),
      children: [
        {
          key: '4-1',
          label: t('list'),
          link: '/vulnerability'
        }, {
          key: '4-2',
          label: t('CVE'),
          link: '/vulnerability/cve'
        }
      ]
    }, {
      key: '5',
      icon: <ServerStackIcon />,
      label: t('asset'),
      link: '/asset'
    }, {
      key: '6',
      icon: <ClipboardDocumentListIcon />,
      label: t('Report'),
      link: '/report'
    }, {
      key: '7',
      icon: <UserGroupIcon />,
      label: t('Users'),
      link: '/user'
    }, {
      key: '8',
      icon: <LinkIcon />,
      label: t('Connectors'),
      link: '/connectors'
    }
  ];

  const customTheme = {
    menuItems: {
      darkItemSelectedBg: '#ff0000'
    }
  };

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const getSelectedAndOpenKeys = (pathname: any) => {
    let selectedKey = '1'; // Default key
    let openKeys = [];
    for (const item of menuItemsNew) {
      if (item.link === pathname) {
        selectedKey = item.key;
      }
      if (item.children) {
        for (const subItem of item.children) {
          if (subItem.link === pathname) {
            selectedKey = subItem.key;
            openKeys.push(item.key);
          }
        }
      }
    }
    return { selectedKey, openKeys };
  };

  const { selectedKey } = getSelectedAndOpenKeys(pathname);

  const onOpenChange = (keys: any) => {
    setOpenKeys(keys);
  };

  return (
    <aside
      ref={sidebar}
      className={`
        absolute left-0 top-0 z-9999 flex h-screen w-full flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 
        ${!sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
      `}
    >
      {/* <!-- SIDEBAR HEADER --> */}
      <div
        className="flex items-center justify-between gap-2 px-6 py-5.5 lg:py-6.5"
      >
        <Logo />
        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="hidden"
        >
          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="hidden"
          >
            <svg
              className="fill-current"
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                fill=""
              />
            </svg>
          </button>
        </button>
      </div>
      {/* <!-- SIDEBAR HEADER --> */}
      <div
        className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear"
      >
        <nav className="mt-5 py-4 lg:mt-9 ">
          <div>
            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
              MENU
            </h3>
            {/* <!-- Menu Item --> */}
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    darkItemSelectedBg: '#333a48',
                    itemPaddingInline: '100px',
                    darkItemSelectedColor: '#fff '
                  }
                }
              }}
            >
              <Menu
                style={{ width: 256 }}
                selectedKeys={[selectedKey]}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                mode="inline"
                theme="dark"
                className='bg-transparent pr-2 '
                overflowedIndicatorPopupClassName='bg-graydark select-none'
                items={menuItemsNew.map(item => (
                  item.children ? {
                    key: item.key,
                    icon: <div className='w-5 h-5'>{item.icon}</div>,
                    label: item.label,
                    children: item.children.map(subItem => ({
                      key: subItem.key,
                      label: <NavLink
                        to={subItem.link}
                      >{subItem.label}</NavLink>
                    }))
                  } : {
                    key: item.key,
                    icon: <div className='w-5 h-5'>{item.icon}</div>,
                    label: <NavLink to={item.link} >{item.label}</NavLink>
                  }
                ))}
              />
            </ConfigProvider>
            <ul className="mb-6 flex-col gap-1.5 hidden">
              {menuItems.map((item, index) => (
                <IconButtonSideBar
                  key={index}
                  name={item.name}
                  Icon={item.icon}
                  link={item.link}
                  isActive={pathname === item.link}
                />
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default SidebarCustom;

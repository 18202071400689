import { AUTH_BASE_URL } from '../../config/serverApiConfig';

import axios from 'axios';
import errorHandler from '../../request/errorHandler';
import successHandler from '../../request/successHandler';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../redux/auth/selectors';

export const login = async ({ email, password }: { email: string; password: string }) => {
  try {
    const response = await axios.post(
      AUTH_BASE_URL + 'login',
      { email, password }
    );

    const { status, data } = response;

    console.log('--------- data ', data);
    
    response.data = data;
    console.log('response data ', response);
    successHandler(
      { response: response.data , status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return { status, data };
  } catch (error) {
    console.log('Errorrr , ', error);
    return errorHandler(error);
  }
};

export const logout = async () => {
  try {
    const user = localStorage.getItem('auth');
    const tmpAuth = JSON.parse(user!).current;

    // Get the current path and store it in local storage for future redirection
    const currentPath = window.location.pathname;
    window.localStorage.setItem('lastVisitedPath', currentPath);

    window.localStorage.removeItem('auth');
    const response = await axios.delete(AUTH_BASE_URL + 'logout', {
      headers: {
        'Authorization': `Bearer ${tmpAuth.access_token}`
      }
    });
    const { status, data } = response;

    successHandler(
      { data, status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return 'data';
  } catch (error) {
    console.log('----- this sis error ', error);
    // return errorHandler(error);
  }
};
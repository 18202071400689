import * as actionTypes from './types';

const INITIAL_KEY_STATE = {
  result: null
};
const INITIAL_STATE = {
  current: {
    result: null
  },
  reportData: {
    name: '',
    client_name: '',
    project: '',
    vuln_filter: 1,
    asset_list: [{
      id: 1,
      ip: '10.0.0.0',
      category: 'Category',
      sub_category: 'Sub Category',
      product: 'Product',
      port: 22,
      risk: 10
    }, {
      id: 2,
      ip: '5.5.5.5',
      category: 'Category',
      sub_category: 'Sub Category',
      product: 'Product',
      port: 22,
      risk: 50
    }, {
      id: 3,
      ip: '1.1.1.1',
      category: 'Category',
      sub_category: 'Sub Category',
      product: 'Product',
      port: 22,
      risk: 100
    }, {
      id: 4,
      ip: '2.2.2.2',
      category: 'Category',
      sub_category: 'Sub Category',
      product: 'Product',
      port: 22,
      risk: 40
    }, {
      id: 5,
      ip: '3.3.3.3',
      category: 'Category',
      sub_category: 'Sub Category',
      product: 'Product',
      port: 22,
      risk: 60
    }],
    vulnerabilities: [{
      id: 1,
      references: [
        'https://nvd.nist.gov/vuln/detail/CVE-2021-44228', 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228', 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228', 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228'
      ],
      exploit_ease: 'No exploit',
      resolution: 'Description of the vulnerability here refer to https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and  https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and    https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and',
      response: 'Description of the vulnerability here refer to https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and  https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and    https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and',
      data: 'Description of the vulnerability here refer to https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and  https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and    https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and',
      request: 'Description of the vulnerability here refer to https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and  https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and    https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and',
      description: 'Description of the vulnerability here refer to https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and  https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and    https://nvd.nist.gov/vuln/detail/CVE-2021-44228 for more details of the vulnerability and',
      cpe: 'CPE',
      tags: ['tag1', 'tag2'],
      cvss_v3: 9.1,
      risk: 100,
      impact: 'Impact',
      path: 'Path',
      confirmed: true,
      ip: '255.255.255.255',
      statut: 'Reopened',
      resolution: 'Resolution',
      severity: 'Critical',
      hostname: 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228',
      asset: '10.0.0.0',
      name: 'Name',
      cve: [
        'CVE-2021-44228', 'CVE-2021-44228'
      ],
      cwe: [
        'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421'
      ]
    }, {
      id: 1,
      references: [
        'https://nvd.nist.gov/vuln/detail/CVE-2021-44228'
      ],
      exploit_ease: 'No exploit',
      resolution: 'qsdmlfkjsqdmlkfj',
      response: 'qsdmlfkjsqdmlkfj',
      data: 'Data',
      request: 'Requst',
      ip: '10.0.0.0',
      description: 'Description',
      cpe: 'CPE',
      tags: ['tag1', 'tag2'],
      cvss_v3: 5.6,
      risk: 40,
      impact: 'Impact',
      path: 'Path',
      confirmed: true,
      statut: 'Mitigated',  
      resolution: 'Resolution',
      severity: 'Critical',
      host_name: 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228',
      asset: '10.0.0.0',
      name: 'Name',
      cve: [
        'CVE-2021-44228', 'CVE-2021-44228'
      ],
      cwe: [
        'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421', 'CWE-421'
      ]
    }]
  },
  create: INITIAL_KEY_STATE,
  update: INITIAL_KEY_STATE,
  delete: INITIAL_KEY_STATE,
  read: INITIAL_KEY_STATE
};

const reportReducer = (state = INITIAL_STATE, action) => {
  const { payload, keyState } = action;
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.CURRENT_ITEM:
      return {
        ...state,
        current: {
          result: payload
        }
      };
    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: true
        }
      };
    case actionTypes.REQUEST_FAILED:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: false,
          isSuccess: false
        }
      };
    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        [keyState]: {
          result: payload,
          isLoading: false,
          isSuccess: true
        }
      };
    case actionTypes.CURRENT_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_KEY_STATE,
          current: payload
        }
      };
    case actionTypes.RESET_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_STATE[keyState]
        }
      };

    case actionTypes.UPDATE_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          ...action.payload // Merge new visibility flags into the current state
        }
      };
    case actionTypes.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          ...payload
        }
      };
    default:
      return state;
  }
};

export default reportReducer;

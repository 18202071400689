import * as actionTypes from './types';
import * as authService from '../../service/auth';
import { getAccountFromToken } from '../../service/auth/jwt.service';
export const login =
  ({ email, password }) =>
    async (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOADING
      });
      const { status, data } = await authService.login({ email, password });

      if (status === 200) {
        data.userName = getAccountFromToken(data.access_token).preferred_username;
        const auth_state = {
          current: data,
          isLoggedIn: true,
          isLoading: false,
          isSuccess: true
        };
        window.localStorage.setItem('auth', JSON.stringify(auth_state));
        window.localStorage.removeItem('isLogout');
        dispatch({
          type: actionTypes.REQUEST_SUCCESS,
          payload: data
        });
        // window.location.href = '/';
      } else {
        dispatch({
          type: actionTypes.REQUEST_FAILED
        });
      }
    };

export const logout = () => async (dispatch) => {
  dispatch({
    type: actionTypes.LOGOUT_SUCCESS
  });
  await authService.logout();
  const result = window.localStorage.getItem('auth');
  const tmpAuth = JSON.parse(result);
  const settings = window.localStorage.getItem('settings');
  const tmpSettings = JSON.parse(settings);
  window.localStorage.removeItem('auth');
  window.localStorage.removeItem('settings');
  window.localStorage.setItem('isLogout', JSON.stringify({ isLogout: true }));
  // if (data.success === false) {
  //   const auth_state = {
  //     current: tmpAuth,
  //     isLoggedIn: true,
  //     isLoading: false,
  //     isSuccess: true
  //   };
  //   window.localStorage.setItem('auth', JSON.stringify(auth_state));
  //   window.localStorage.setItem('settings', JSON.stringify(tmpSettings));
  //   window.localStorage.removeItem('isLogout');
  //   dispatch({
  //     type: actionTypes.LOGOUT_FAILED,
  //     payload: data.result
  //   });
  // } else {
  //   // on lgout success
  // }
};

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';
import { Table, flexRender } from '@tanstack/react-table';
import React from 'react';

interface TableCustomProps<T> {
  table: Table<T>;
  repeatCount: number;
}

const LoadingTable = <T,>({ table }: TableCustomProps<T>) => (
  <table className="" >
    <tbody
      className=""
    >
      <thead
        className="bg-gray-50 text-normal uppercase tracking-wider sticky top-[-1px] whitespace-nowrap z-10 boder-b border-blue-gray-100 border"
      >
        {table.getFlatHeaders().map((header) => (
          <th
            key={header.id}
            className="border-y border-blue-gray-100 bg-[#f6f7f8] px-4 py-2 hover:bg-gray"
            colSpan={header.colSpan}
            style={{ position: 'relative', width: header.getSize() }}
          >
            <div
              className={
                header.column.getCanSort()
                  ? 'cursor-pointer select-none'
                  : ''}
              onClick={header.column.getToggleSortingHandler()}
            >

            </div>
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal leading-none opacity-70 flex items-center gap-2"
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              {{
                asc: <ChevronUpIcon
                  height={15}
                  width={15}
                  strokeWidth={2}
                  className='text-black'
                />,
                desc: <ChevronDownIcon
                  height={15}
                  width={15}
                  strokeWidth={2}
                  className='text-black'
                />
              }[header.column.getIsSorted() as string] ?? null}
            </Typography>
          </th>
        ))}
      </thead>
      <tbody >
        {Array.from({ length: 11 }).map((_, index) => (
          <tr
            className='border-y border-blue-gray-100 p-4'
            key={index}
          >
            {Array.from({
              length: table.getFlatHeaders().length
            }).map((_, index) => (
              <td
                key={index}
                style={{ position: 'relative' }}
                className="max-w-full p-1 border-gray animate-pulse"
              >
                <div
                  className="w-full bg-gray rounded-lg h-4 my-3 dark:bg-gray-700"
                ></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </tbody>
  </table >
);

export default LoadingTable;
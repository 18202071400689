import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="flex justify-center items-center flex-col h-screen">
    <h1>404</h1>
    <p>Page not found</p>
    <Link to="/" className="text-primary text-lg font-body mt-10">
      Return to home
    </Link>
  </div>
);

export default NotFoundPage;
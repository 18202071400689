import Title from 'antd/es/typography/Title';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface PIE_CHART_PROPS {
  seriesDefault?: number[];
  height?: number;
  title?: string;
  label?: string[];
  colors?: string[];
  type?:
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';
  showPercentage?: boolean
  showDataLabels?: boolean
}
const PieChart: React.FC<PIE_CHART_PROPS> = ({
  seriesDefault = [],
  label = ['critical', 'high', 'medium', 'low', 'information'],
  colors = ['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6'],
  type = 'donut',
  showPercentage = true,
  showDataLabels = false
}) => {

  interface PieChartState {
    series: number[];
  }

  // Calculate percentages for each label
  const total = seriesDefault.reduce((acc, curr) => acc + curr, 0);
  const percentages = seriesDefault.map((value) => ((value / total) * 100).toFixed(2));

  return (
    <div
    >
      {type === 'pie' &&
        <Title level={5}>Total: {total}</Title>
      }
      <div className="mb-2">
        <div id="pieChart" className="mx-auto flex justify-center">
          {total > 0 &&
            <ReactApexChart
              options={{
                noData: {
                  text: undefined,
                  align: 'center',
                  verticalAlign: 'middle',
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                  }
                },
                chart: {
                  type: type
                },
                colors: colors,
                labels: label,
                legend: {
                  show: true,
                  position: 'right'
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: '65%',
                      background: 'transparent',
                      labels: {
                        show: type === 'donut' ? true : false,
                        total: {
                          show: true,
                          label: 'Total',
                          formatter: () => seriesDefault.reduce((acc, curr) => acc + curr, 0).toString()
                        }
                      }
                    }
                  }
                },
                dataLabels: {
                  enabled: showDataLabels,
                  dropShadow: {
                    enabled: false
                  },
                  style: {
                    colors: ['#fff']
                  }
                },
                responsive: [
                  {
                    breakpoint: 2600,
                    options: {
                      chart: {
                        width: 380
                      }
                    }
                  }, {
                    breakpoint: 640,
                    options: {
                      chart: {
                        width: 200
                      }
                    }
                  }
                ]
              }}
              series={seriesDefault}
              type={type}
            />}
        </div>
      </div>
      <div className={`${showPercentage && total > 0 ? '' : 'hidden'}`}>
        {label.map((label, index) => (
          (percentages[index] !== '0.00') &&
          <div key={index} className="flex justify-between">

            <span className='flex gap-2 items-center'>
              <div
                className={'h-4 w-4 rounded-full'}
                style={{ backgroundColor: colors[index] }}
              ></div> {label}
            </span>
            <span>{seriesDefault[index]} ({percentages[index]}%)</span>
          </div>
        ))
        }
      </div >
    </div >
  );
};

export default PieChart;

import { Skeleton, TableColumnsType } from 'antd';
import RiskScoreTag from '../../utils/RiskScoreTag';
import { TOP_RISK_VULN_PROPS } from './type';
import Link from 'antd/es/typography/Link';

export const TopVulsColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'Risk',
    dataIndex: 'risk',
    key: 'risk',
    width: 50,
    render: (text: any) => RiskScoreTag(text)
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }
];
export const TopAssetColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'AVS',
    dataIndex: 'avs',
    key: 'avs',
    width: 50,
    render: (text: any) => RiskScoreTag(text || 0)
  }, {
    title: 'Asset',
    dataIndex: 'asset',
    key: 'asset',
    render: (text: any, record) => record.hostname ? record.hostname : text
  }
];
export const MostVulnsProjectColumns: TableColumnsType<TOP_RISK_VULN_PROPS> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 20,
    render: (text: any) => RiskScoreTag(text)
  }, {
    title: 'Risk Score',
    dataIndex: 'risk',
    key: 'risk',
    width: 20,
    render: (text: any) => RiskScoreTag(text)
  }, {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  }
];
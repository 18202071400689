import { API_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import { AxiosError } from 'axios';

const getGlobalDashboard = async () => {
  try {

    let url = `${API_BASE_URL}global/dashboard`;
    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export default { 
  getGlobalDashboard
};
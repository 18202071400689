import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link
} from '@react-pdf/renderer';
import logoImage from '../../../../assets/image/logoSeeWard.png';
import MyTable from './Tables/PdfTable';
import ProjectOverview from './Tables/Project';

// Font.register({
//   family: 'Oswald',
//   src: 'https://fonts.cdnfonts.com/css/helvetica-neue-55'
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 35,
    paddingHorizontal: 15
  },
  titleOfReport: {
    fontSize: 40,
    marginTop: 100,
    marginHorizontal: 15,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  author: {
    fontSize: 25,
    marginTop: 40,
    marginHorizontal: 15,
    textAlign: 'center'
  },
  date: {
    fontSize: 15,
    marginHorizontal: 25,
    color: 'grey',
    textAlign: 'center'
  },
  title: {
    fontSize: 20,
    margin: 12
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginHorizontal: 12,
    marginVertical: 2
  },
  text: {
    fontSize: 9,
    textAlign: 'justify',
    marginHorizontal: 15,
    color: '#404344'
  },
  textRequestAdnResponse: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    color: 'grey',
    padding: 10,
    backgroundColor: '#e0e0e0',
    borderRadius: 5
  },
  listContainer: {
    padding: 5,
    borderRadius: 5
  },
  listItem: {
    fontSize: 12,
    paddingVertical: 5,
    color: '#000', // Change as needed
    marginLeft: 20, // To simulate bullet space
    position: 'relative'
  },
  dot: {
    position: 'absolute',
    left: 0,
    top: 0,
    color: 'black' // Dot color
  },
  image: {
    width: 150,
    height: 30,
    position: 'absolute',
    left: 0
  },
  pdfImg: {
    width: '100%',
    marginVertical: 10
  },
  header: {
    fontSize: 14,
    textAlign: 'left',
    color: 'black'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    marginHorizontal: 10,
    left: 0,
    right: 0,
    textAlign: 'right',
    color: 'grey'
  },
  containerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 0
  },
  link: {
    color: '#182a83',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 14
  },
  reportIndexItem: {
    fontSize: 14,
    marginHorizontal: 70,
    marginTop: 10
  },
  h6: {
    fontSize: 15,
    fontWeight: 'bold',
    marginHorizontal: 12,
    marginVertical: 8,
    color: '#009edf'
  },
  h6header: {
    fontSize: 12,
    marginVertical: 2,
    color: 'black'
  },
  textTech: {
    marginHorizontal: 12,
    fontSize: 8,
    textAlign: 'justify',
    color: '#404344'
  },
  h6Bold: {
    fontSize: 10,
    fontWeight: 'bold',
    marginHorizontal: 12,
    color: '#182a83'
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ECECEC',
    borderBottomStyle: 'solid',
    alignItems: 'stretch'
  },
  tableColHeader: {
    padding: 3
  },
  tableCol: {
    padding: 5
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  tableCell: {
    fontSize: 10
  },
  idCol: {
    width: '15%'
  },
  nameCol: {
    flex: 1
  },
  severityCol: {
    width: '10%'
  }
});
const vulnerabilityOverView = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 5
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e0e0e0'
  },
  textBox: {
    margin: 5,
    fontSize: 10,
    color: '#fff',
    paddingVertical: 20
  },
  boxLabel: {
    fontSize: 10,
    color: '#000',
    backgroundColor: '#e0e0e0',
    padding: 5,
    textAlign: 'center'
  },
  critical: {
    backgroundColor: '#8B008B'
  },
  high: {
    backgroundColor: '#FF0000'
  },
  medium: {
    backgroundColor: '#FFA500'
  },
  low: {
    backgroundColor: '#008000'
  },
  informational: {
    backgroundColor: '#87CEEB'
  },
  labelContainer: {
    flexDirection: 'row',
    marginTop: 0
  }
});

const TechnicalDetails = StyleSheet.create({
  sectionContainer: {
    marginVertical: 5,
    padding: 5,
    borderWidth: 1,
    borderColor: '#ECECEC',
    borderRadius: 5
  },
  rowContainer: {
    gap: 10,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start' // Align items to the top
  },
  sectionTitle: {
    fontSize: 12,
    marginBottom: 10
  },
  keyValueRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 3
  },
  key: {
    width: '25%',
    fontSize: 10,
    color: 'grey',
    fontWeight: 'normal'
  },
  value: {
    width: '75%',
    fontSize: 10,
    fontWeight: 'normal'
  }
});

const date = new Date();
const formattedDate = date.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});

const FormSection = ({ title, content }: any) => {
  if (!content) {
    return null;
  }

  return (
    <View>
      <Text style={styles.h6}>{title}</Text>
      <Text style={styles.text}>{content}</Text>
    </View>
  );
};

const Template1 = ({ formData, withVulnerabilitiesData = false }: any) => <Document>
  <Page style={styles.body}>
    {ReporteHeaderBottom()}
    <Text
      style={styles.titleOfReport}
    >{formData.name ? formData.name : 'Technical  Report'}</Text>
    <Text style={styles.author}>{formData.client_name}</Text>
    <Text style={styles.date}>{formattedDate}</Text>

    {formData.image && <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginTop: 20
      }}
    >
      <Image
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: 100,
          height: 200,
          objectFit: 'contain'
        }}
        src={formData.image}
      />
    </View>}
  </Page>
  <Page style={styles.body}>
    {ReporteHeaderBottom()}
    <Text style={{ ...styles.title }}>Report index</Text>
    <Text style={styles.reportIndexItem}>
      <Link
        style={styles.link}
        src='#executive_summary'
      >Executive Summary</Link> ..................................................................... 3
    </Text>
    <Text style={styles.reportIndexItem}>
      <Link
        style={styles.link}
        src='#summary_of_findings'
      >Summary of findings</Link> .................................................................... 4
    </Text>
    <Text style={styles.reportIndexItem}>
      <Link
        style={styles.link}
        src='#technical_summary'
      >Technical Summary</Link> ..................................................................... 10
    </Text>
  </Page>

  <Page style={styles.body} id='executive_summary'>
    {ReporteHeaderBottom()}
    <Text
      style={{ ...styles.subtitle }}
    >Executive Summary</Text>
    <Text style={[styles.h6, { marginHorizontal: 10 }]}>Targets Overview</Text>
    <Text style={[styles.text, { marginHorizontal: 10, marginBottom: 10 }]}>
      We did a scan on the project <Text
        style={{ fontWeight: 'bold', color: '#182a83' }}
      >{formData.project}</Text>
    </Text>
    <ProjectOverview
      totalVuln={formData.vulnerabilities.length}
      totalAsset={formData.asset_list.length}
      totalRisk={80}
    />

    <FormSection
      title="Findings overview"
      content="Throughout auditing, vulnerabilities were found in total."
    />
    <FormSection title="Summary" content={formData.summary} />
    <FormSection title="Recommendations" content={formData.recommendations} />
    <FormSection title="Scope" content={formData.scope} />
    <FormSection title="Objectives" content={formData.objectives} />
    <FormSection title="Conclusions" content={formData.conclusions} />
  </Page>

  <Page id='summary_of_findings' style={styles.body}>
    {ReporteHeaderBottom()}
    <View
      wrap={false}
    >
      <View style={vulnerabilityOverView.container}>
        <View
          style={[vulnerabilityOverView.box, vulnerabilityOverView.critical, {
            borderTopLeftRadius: 5
          }]}
        >
          <Text
            style={vulnerabilityOverView.textBox}
          >{formData?.severityCounts?.critical}</Text>
        </View>
        <View style={[vulnerabilityOverView.box, vulnerabilityOverView.high]}>
          <Text
            style={vulnerabilityOverView.textBox}
          >{formData?.severityCounts?.high}</Text>
        </View>
        <View
          style={[vulnerabilityOverView.box, vulnerabilityOverView.medium]}
        >
          <Text
            style={vulnerabilityOverView.textBox}
          >{formData?.severityCounts?.medium}</Text>
        </View>
        <View style={[vulnerabilityOverView.box, vulnerabilityOverView.low]}>
          <Text
            style={vulnerabilityOverView.textBox}
          >{formData?.severityCounts?.low}</Text>
        </View>
        <View
          style={[vulnerabilityOverView.box, vulnerabilityOverView.informational, {
            borderTopRightRadius: 5
          }]}
        >
          <Text
            style={vulnerabilityOverView.textBox}
          >{formData?.severityCounts?.informational}</Text>
        </View>
      </View>
      <View style={vulnerabilityOverView.labelContainer}>
        <View
          style={[vulnerabilityOverView.box, { borderBottomLeftRadius: 5 }
          ]}
        >
          <Text
            style={vulnerabilityOverView.boxLabel}
          >Critical</Text>
        </View>
        {['High', 'Medium', 'Low'].map((label, index) => (
          <View
            key={index}
            style={[vulnerabilityOverView.box
            ]}
          >
            <Text style={vulnerabilityOverView.boxLabel}>{label}</Text>
          </View>
        ))}
        <View
          style={[vulnerabilityOverView.box, { borderBottomRightRadius: 5 }
          ]}
        >
          <Text
            style={vulnerabilityOverView.boxLabel}
          >Informational</Text>
        </View>
      </View>
    </View>

    <Text style={[styles.h6, { marginTop: 30 }]}>Vulnerability Overview</Text>
    <MyTable data={formData.charts} />
  </Page>

  <Page id='summary_of_findings' style={styles.body}>
    {ReporteHeaderBottom()}
    <Text
      style={{ ...styles.subtitle, marginTop: 10 }}
    >Summary of findings</Text>
    <Text
      style={[styles.h6, { marginTop: 30 }]}
    >List of assets</Text>
    <View style={styles.table}>
      {/* Table Header */}
      <View
        style={{
          ...styles.tableRow,
          backgroundColor: '#ECECEC',
          borderRadius: 5
        }}
      >
        <View style={[styles.tableColHeader, { width: '20%' }]}>
          <Text style={styles.tableCellHeader}>Asset</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '20%' }]}>
          <Text style={styles.tableCellHeader}>Category</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '20%' }]}>
          <Text style={styles.tableCellHeader}>Sub-category</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '20%' }]}>
          <Text style={styles.tableCellHeader}>Product</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '10%' }]}>
          <Text style={styles.tableCellHeader}>Port</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '10%' }]}>
          <Text style={styles.tableCellHeader}>Risk</Text>
        </View>
      </View>
      {/* Example Table Row with Data */}
      {formData.asset_list.map((item: any, index: any) => (
        <View key={index} style={styles.tableRow} >
          <View style={[styles.tableCol, { width: '20%' }]}>
            <Text style={styles.tableCell}>{item.ip}</Text>
          </View>
          <View style={[styles.tableCol, { width: '20%' }]}>
            <Text style={styles.tableCell}>{item.category}</Text>
          </View>
          <View
            style={[styles.tableCol, { width: '20%' }]}
          >
            <Text style={styles.tableCell}>{item.sub_category}</Text>
          </View>
          <View style={[styles.tableCol, { width: '20%' }]}>
            <Text style={styles.tableCell}>{item.product}</Text>
          </View>
          <View style={[styles.tableCol, styles.severityCol]}>
            <Text
              style={[styles.tableCell, { width: '10%' }]}
            >{item.port}</Text>
          </View>
          <View style={[styles.tableCol, styles.severityCol]}>
            <Text
              style={[styles.tableCell, {
                width: '10%',
                color: getBgColorRisk(item.risk ? item.risk : 0)
              }]}
            >{item.risk}</Text>
          </View>
        </View>
      ))}
      {/* Additional rows with data will be mapped and added here */}
    </View>
    <Text
      style={[styles.h6, { marginTop: 30 }]}
    >List of vulnerabilities</Text>
    <View style={styles.table}>
      {/* Table Header */}
      <View
        style={{
          ...styles.tableRow,
          backgroundColor: '#ECECEC',
          borderRadius: 5
        }}
      >
        <View style={[styles.tableColHeader, styles.idCol]}>
          <Text style={styles.tableCellHeader}>Id</Text>
        </View>
        <View style={[styles.tableColHeader, styles.nameCol]}>
          <Text style={styles.tableCellHeader}>Name</Text>
        </View>
        <View style={[styles.tableColHeader, styles.severityCol]}>
          <Text style={styles.tableCellHeader}>Severity</Text>
        </View>
        <View style={[styles.tableColHeader, { width: '100px' }]}>
          <Text style={styles.tableCellHeader}>Asset</Text>
        </View>
        <View style={[styles.tableColHeader, styles.severityCol]}>
          <Text style={styles.tableCellHeader}>Risk</Text>
        </View>
      </View>
      {/* Example Table Row with Data */}
      {formData.vulnerabilities.map((item: any, index: any) => (
        <View key={index} style={styles.tableRow} >
          <View style={[styles.tableCol, styles.idCol]}>
            <Link src={`#${item.id}`} >
              <Text style={styles.tableCell}>{item.id}</Text>
            </Link>
          </View>
          <View style={[styles.tableCol, styles.nameCol]}>
            <Text style={styles.tableCell}>{item.name}</Text>
          </View>
          <View
            style={[styles.tableCol, styles.severityCol, {
              color: getColorOfSeverity(item.severity)
            }]}
          >
            <Text style={styles.tableCell}>{item.severity}</Text>
          </View>
          <View style={[styles.tableCol, { width: '100px' }]}>
            <Text style={styles.tableCell}>{item.ip}</Text>
          </View>
          <View style={[styles.tableCol, styles.severityCol]}>
            <Text
              style={[styles.tableCell, {
                width: '20px',
                padding: 1,
                borderRadius: 5,
                textAlign: 'center',
                color: getBgColorRisk(item.risk)
              }]}
            >{item.risk}</Text>
          </View>
        </View>
      ))}
      {/* Additional rows with data will be mapped and added here */}
    </View>
  </Page>

  {withVulnerabilitiesData ? PageOfVulnerability(formData) : null}
</Document>;

const ReporteHeaderBottom = (logoText?: string) => <>
  <View
    style={[styles.containerHeader, { marginBottom: logoText ? 5 : 30 }]}
    fixed
  >
    {logoText ? <Text
      style={[styles.header, { color: 'grey', fontSize: 12 }]}
    >{logoText}</Text> : <Image
      style={styles.image}
      src={logoImage}
      fixed
    />}

    <Text style={styles.header}></Text>
    <Text
      style={[styles.header, {
        color: logoText ? 'grey' : 'black',
        fontSize: logoText ? 12 : 14
      }]}
    >Technical  Report</Text>
  </View>
  <Text
    style={styles.pageNumber}
    render={({ pageNumber, totalPages }) => (
      `${pageNumber} / ${totalPages}`
    )}
    fixed
  /></>;
export default Template1;

const KeyValueRow = ({ label, value, component, style = {
  key: {},
  value: {}
} }: any) => (
  <View style={[TechnicalDetails.keyValueRow]}>
    <Text style={[TechnicalDetails.key, style.key]}>{label}</Text>
    {component ?
      <View style={[TechnicalDetails.value]}>
        {component}
      </View>
      : <Text
        style={[TechnicalDetails.value, style.value]}
      >{value}</Text>
    }
  </View>
);

const DescriptionView = ({ item, title, withBorder }: { item: any, title: string, withBorder?: boolean }) => (
  item ? (
    <View style={{ marginTop: 10 }}>
      <Text style={styles.h6header}>{title}</Text>
      <Text
        style={[styles.textTech, {
          marginTop: 5,
          padding: withBorder ? 5 : 0,
          borderRadius: withBorder ? 5 : 0,
          backgroundColor: withBorder ? '#ECECEC' : 'white'
        }]}
      >
        {item}
      </Text>
    </View>
  ) : null
);

const CVEAndCWE = ({ title, items, idPrefix = '' }: any) => (
  items?.length > 0 ? (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center'
      }}
    >
      {items.map((item: any, index: any) => (
        <Link
          key={item}
          style={{
            ...styles.text,
            marginHorizontal: 0,
            marginLeft: index === 0 ? 0 : 10,
            marginVertical: 2,
            color: 'black'
          }}
          href={
            idPrefix === 'CVE' ?
              `https://cve.mitre.org/cgi-bin/cvename.cgi?name=${item}` :
              `https://cwe.mitre.org/data/definitions/${item.replace('CWE-', '')}.html`}
        >
          {item}
        </Link>
      ))}
    </View>
  ) : null
);

const getColorOfSeverity = (severity: string) => {
  switch (severity.toLowerCase()) {
    case 'critical':
      return 'purple';
    case 'high':
      return 'red';
    case 'medium':
      return 'yellow';
    case 'low':
      return 'green';
    case 'information':
      return 'blue';
    default:
      return 'black';
  }
};

const getStatut = (status?: string) => {
  switch (status?.toLowerCase()) {
    case 'detected':
      return 'red';
    case 'analysed':
      return 'orange';
    case 'mitigated':
      return 'green';
    case 'fixed':
      return 'blue';
    case 'solved':
      return 'purple';
    case 'reopened':
      return 'orange';
    case 'false_positive':
      return 'teal';
    case 'risk_accepted':
      return 'indigo';
    default:
      return 'black';
  }
};

const getBgColorRisk = (score: number) => {
  if (score === 0) return 'green'; // Low
  if (score <= 30.9) return 'blue'; // Informational
  if (score <= 60.9) return '#f1c40f'; // Medium
  if (score <= 88.9) return 'red'; // High
  return 'purple'; // Critical
};

const getColorCVSS = (score: number) => {
  if (score === 0) return 'green'; // Low
  if (score <= 3.9) return 'blue'; // Informational
  if (score <= 6.9) return 'yellow'; // Medium
  if (score <= 8.9) return 'red'; // High
  return 'purple'; // Critical
};

const PageOfVulnerability = (formData: any) => formData.vulnerabilities.map((item: any, index: any) => (
  <Page
    style={styles.body}
  >
    {ReporteHeaderBottom('SeeWard')}
    {index === 0 ? <Text
      id='technical_summary'
      style={{ ...styles.subtitle }}
    >Technical summary</Text> :
      null}

    <View key={index}>
      <View
        style={TechnicalDetails.sectionContainer}
        id={item.id}
        key={index}
      >
        <Text
          style={{
            padding: 2,
            marginBottom: 5,
            color: 'black',
            fontSize: 12,
            backgroundColor: '#ECECEC',
            borderRadius: 5
          }}
        > {item.name}</Text>

        <KeyValueRow label="ID" value={item.id} />
        <KeyValueRow
          label="Asset"
          value={item.ip}
        />
        <KeyValueRow
          label="Website"
          value={item.host_name}
        />
        <KeyValueRow label="Path" value={item.path} />
        <KeyValueRow
          label="Severity"
          value={item.severity}
          style={{
            value: {
              color: getColorOfSeverity(item.severity),
              fontWeight: 'bold'
            }
          }}
        />
        <KeyValueRow label="Ease of Resolution" value={item.exploit_ease} />
        <KeyValueRow
          label="Status"
          value={item.statut}
          style={{
            value: { color: getStatut(item.statut), fontWeight: 'bold' }
          }}
        />
        <KeyValueRow 
          label="Exploit difficulty" 
          value={item.exploit_difficulty} 
        />
        <KeyValueRow label="Confirmed" value={item.confirmed ? 'Yes' : 'No'} />
        <KeyValueRow label="Impact" value={item.impact} />
        <KeyValueRow
          label="Risk Score"
          value={item.risk}
          style={{
            value: {
              color: getBgColorRisk(item.risk),
              fontWeight: 'bold'
            }
          }}
        />
        <KeyValueRow
          label="CVSS v3.1"
          value={item.cvss3_base_score}
          style={{
            value: {
              fontWeight: 'bold',
              color: getColorCVSS(item.cvss3_base_score),
              fontSize: 12
            }
          }}
        />
        <KeyValueRow label="Tags" value={item.tags.join(', ')} />
        <KeyValueRow label="CPE" value={item.cpe} />
        <KeyValueRow label="EPSS" value={item.epss} />
        <KeyValueRow
          label="CVE"
          component={<CVEAndCWE items={item.cve} idPrefix="CVE" />}
        />
        <KeyValueRow
          label="CWE"
          component={<CVEAndCWE title="CWE" items={item.cwe} idPrefix="CWE" />}
        />
        <KeyValueRow label="Source" value={item.source} />
        <KeyValueRow label="Last scan" value={item.last_scan} />
        <KeyValueRow label="Created at" value={item.created_at} />
      </View>

      <DescriptionView item={item.description} title="Description" />
      <DescriptionView item={item.data} title="Data" />
      <DescriptionView item={item.request} title="Request" withBorder />
      <DescriptionView item={item.response} title="Response" withBorder />
      <DescriptionView item={item.resolution} title="Resolution" />

      {item.evidences?.length > 0 ?
        <View style={{ marginTop: 10 }}>
          <Text style={styles.h6header}>Evidence</Text>
          <View style={styles.listContainer}>
            {item.evidences?.map((evidences: any, index: number) => (
              <Image
                style={styles.pdfImg}
                src={evidences}
              />
            ))}
          </View>
        </View> :
        <div />
      }
      {item.references?.length > 0 ?
        <View style={{ marginTop: 10 }}>
          <Text style={styles.h6header}>References</Text>
          <View style={styles.listContainer}>
            {item.references?.map((reference: any, index: number) => (
              <Text
                style={[styles.textTech, {
                  marginTop: 5
                }]}
                key={index}
              >
                <Text style={styles.dot}>• </Text>
                {reference}
              </Text>
            ))}
          </View>
        </View> : <div />
      }
    </View>
  </Page>
));
import { Tag } from 'antd';

const getBgColor = (score: number) => {
  if (score <= 30) return 'bg-green-500'; // Low
  if (score <= 60) return 'bg-yellow-500 !text-black'; // Medium
  if (score <= 80) return 'bg-red-500'; // High
  return 'bg-purple-500'; // Critical
};
const getStatus = (score: number) => {
  if (score <= 30) return 'Low';
  if (score <= 60) return 'Medium';
  if (score <= 80) return 'High';
  return 'Critical';
};

const RiskScoreTag = (risk?: number, className = '', isStatus = false) => {
  if (risk == null) return <Tag color="default">N/A</Tag>;
  return <div
    className={`min-w-[30px] px-1 h-[25px] flex justify-center items-center rounded-md text-white text-center ${getBgColor(risk!)} font-bold ${className}`}
  >{isStatus ? getStatus(risk) : risk}</div>;
};

const RiskScoreContainer: React.FC<{ riskScore: number }> = ({ riskScore }) => <div
  className="mx-auto mt-4.5 mb-5.5 grid max-w-[200px] grid-cols-1 rounded-md border border-stroke py-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]"
>
  <div
    className="flex flex-col items-center justify-center gap-1 border-stroke px-4 dark:border-strokedark xsm:flex-row"
  >
    <span className="text-lg font-bold">Risk score</span>
    <div
      className={`min-w-[30px] px-1 rounded-md text-center ${getBgColor(riskScore)} font-semibold  text-white`}
    >{riskScore}</div>
  </div>
</div>;

export { RiskScoreContainer };
export default RiskScoreTag;
export const RESET_STATE = 'ACTION_PLAN_RESET_STATE';
export const CURRENT_ITEM = 'ACTION_PLAN_CURRENT_ITEM';

export const REQUEST_LOADING = 'ACTION_PLAN_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'ACTION_PLAN_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'ACTION_PLAN_REQUEST_FAILED';

export const CURRENT_ACTION = 'ACTION_PLAN_CURRENT_ACTION';
export const RESET_ACTION = 'ACTION_PLAN_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_PAGINATION = 'ACTION_PLAN_UPDATE_PAGINATION';

export const RESET_LIST = 'ACTION_PLAN_RESET_LIST';
import { Input} from 'antd';
import React, {
  useState,
  useEffect,
  ChangeEvent
} from 'react';
import { SearchOutlined } from '@ant-design/icons';

interface SearchInputProps {
  onSearch: (value: string) => void; // Callback for when a search is triggered
  value?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ onSearch, value = '' }) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const debounceTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const handleSearch = (value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    onSearch(value);
  };

  useEffect(() => {
    if (inputValue === '') {
      handleSearch('');
    } else {
      debounceTimeoutRef.current = setTimeout(() => {
        handleSearch(inputValue);
      }, 500); // 500ms delay
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [inputValue, onSearch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Input
      prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      className='max-w-[200px] w-[200px]'
      height={40}
      width={200}
      allowClear
      placeholder="Type to search..."
      value={inputValue}
      onChange={handleChange}
      variant="filled"
      inputMode='search'
    />
  );
};

export default SearchInput;

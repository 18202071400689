import { Checkbox } from '@material-tailwind/react';
import { useField } from 'formik';

const CheckBoxField = ({ name, label }: { name: string, label: string }) => {

  const [field, , helpers] = useField(name);

  const handleCheckboxChange = () => {
    // Toggle the checkbox value
    helpers.setValue(!field.value);
  };

  return <Checkbox
    name={name}
    id={name}
    crossOrigin
    color="indigo"
    ripple={false}
    checked={field.value}
    onClick={handleCheckboxChange}
    className="hover:before:content-none"
    label={label}
  />;
};

export default CheckBoxField;
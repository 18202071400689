import { Avatar, Card, List } from 'antd';
import {
  EditOutlined,
  SettingOutlined,
  DeleteFilled
} from '@ant-design/icons';
import {
  Button
} from '@material-tailwind/react';
import Meta from 'antd/es/card/Meta';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const ReportList = () => {

  const data: any = [];
  const navigate = useNavigate();
  return <div className='mt-10'>
    <Button
      variant="filled"
      className="bg-primary flex items-center"
      size="sm"
      onClick={() => {
        navigate('/report/create');
      }}
    >
      <PlusIcon
        strokeWidth={4}
        className="h-4 w-4 mr-4"
      /> Create New Report
    </Button>

    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={data}
      renderItem={(item: any) => (
        <List.Item>
          <Card
            style={{ width: 300, marginTop: 16 }}
            actions={[
              <SettingOutlined
                key="setting"
              />, <EditOutlined
                key="edit"
              />, <DeleteFilled
                key="ellipsis"
              />
            ]}
          >
            <Meta
              title={item?.title}
              description="This is the description"
            />
          </Card>
        </List.Item>
      )}
    />
  </div>
  ;
};

export default ReportList;

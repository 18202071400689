import { Button } from '@material-tailwind/react';
import { useField } from 'formik';
import { useState } from 'react';

const CheckBoxButton = ({ name, label, checked = false, bgColor = 'bg-black text-white', className = '' }: { name: string, label: string, checked?: boolean, bgColor?: string, className?: string }) => {
  const [field, , helpers] = useField(name);
  const toggleCheckbox = () => {
    helpers.setValue(!field.value);
  };

  return (
    <Button
      fullWidth={false}
      size='sm'
      className={`${field.value ? bgColor : 'bg-bodydark1 text-black'} ${className}`}
      ripple={true}
      onClick={toggleCheckbox}
    >
      {label}
    </Button>
  );
};
export default CheckBoxButton;
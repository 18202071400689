import { NavLink } from 'react-router-dom';
import React from 'react';
import { IconButtonSideBarProps } from './types';
const IconButtonSideBar: React.FC<IconButtonSideBarProps> = ({
  name,
  link,
  Icon,
  isActive = false
}) => (
  <NavLink
    to={link}
    className={`group relative flex items-center gap-2.5 rounded-lg py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 
      ${isActive && 'bg-graydark dark:bg-meta-4'}
    `}
  >
    <div className="h-6 w-6">
      <Icon />
    </div>
    {name}
  </NavLink>
);

export default IconButtonSideBar;

import { jwtDecode } from 'jwt-decode';

export const getAccountFromToken = (token: string) => {
  try {

    const decoded = jwtDecode(token);

    console.log(decoded);
    return decoded;
  } catch (error) {
    console.log(error);
  }
};
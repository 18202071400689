import { Card } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface BarChartState {
  series: {
    name: string;

    data: number[];
  }[];
}

interface BAR_CHART_PROPS {
  seriesDefault?: number[];
  height?: number;
  title?: string;
  categories?: string[];
  extra?: React.ReactNode;
  series?: any[];
  stacked?: boolean;
}

const BarChart: React.FC<BAR_CHART_PROPS> = ({
  title = 'Most vulnerable assets',
  categories = ['---', '---', '---', '---', '---', '---', '---', '---', '---', '---', '---'],
  extra,
  stacked = true,
  series = [{
    name: 'Critical',
    data: [
      18, 35, 201, 298, 17, 15, 91, 110, 215, 390, 280
    ]
  }, {
    name: 'High',
    data: [
      18, 35, 201, 298, 17, 15, 91, 110, 215, 390, 280

    ]
  }, {
    name: 'Medium',
    data: [
      18, 35, 201, 298, 17, 15, 91, 110, 215, 390, 280
    ]
  }, {
    name: 'Low',
    data: [
      18, 35, 201, 298, 17, 15, 91, 110, 215, 390, 280
    ]
  }, {
    name: 'Information',
    data: [
      18, 35, 201, 298, 17, 15, 91, 110, 215, 390, 280
    ]
  }
  ]
}) => {
  const [state, setState] = useState<BarChartState>({
    series
  });

  const options: ApexOptions = {
    colors: [
      '#6F48EC', '#EF4444', '#F59E0B', '#10B981', '#3B82F6'
    ],
    chart: {
      fontFamily: 'Satoshi, sans-serif',
      type: 'bar',
      height: 350,
      stacked: stacked || true,
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          total: {
            enabled: true,
            offsetY: 6,
            offsetX: 10,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    // stroke: {
    //   width: 1,
    //   colors: ['#fff']
    // },
    xaxis: {
      categories: categories,
      labels: {
      }
    },
    // yaxis: {
    //   title: {
    //     text: undefined
    //   }
    // },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'left',
      fontFamily: 'inter',
      markers: {
        radius: 99
      }
    },
    // yaxis: {
    //   title: false,
    // },
    grid: {
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    fill: {
      opacity: 10
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val + '';
        }
      }
    }
  };

  return <Card
    type='inner'
    size='small'
    title={title}
    extra={extra}
  >
    <div className="mb-2">
      <div id="BarChart" className="-ml-5">
        <ReactApexChart
          options={options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  </Card>;
};

export default BarChart;

import { ViewColumnsIcon } from '@heroicons/react/24/outline';
import {
  Checkbox,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList
} from '@material-tailwind/react';
import { ColumnButtonProps, ColumnType } from './type';
import { Column } from '@tanstack/react-table';
import { Divider } from 'antd';

function ColumnButton({ table, getIsAllColumnsVisible, getToggleAllColumnsVisibilityHandler }: ColumnButtonProps) {
  return (
    <Menu
      placement="left-end"
      dismiss={{
        itemPress: false
      }}
    >
      <MenuHandler>
        <IconButton
          size="md"
          variant="text"
          className='rounded-full flex items-center gap-x-3'
        >
          <ViewColumnsIcon className="w-5 h-5" />
        </IconButton>
      </MenuHandler>
      <MenuList className='w-[200px] max-h-[300px]'>
        <MenuItem className="p-0">
          <label
            className="flex cursor-pointer items-center gap-2 p-2"
          >
            <Checkbox
              defaultChecked
              crossOrigin
              color="indigo"
              ripple={false}
              containerProps={{ className: 'p-0' }}
              className="hover:before:content-none"
              checked={getIsAllColumnsVisible()}
              onChange={getToggleAllColumnsVisibilityHandler()}
            />
            Toggle all
          </label>
        </MenuItem>
        <Divider className='m-2' />
        {table.map((column: Column<ColumnType>) => (
          <MenuItem className="p-0" key={column.id}>
            <label
              htmlFor={column.id}
              className={`flex ${!column.getCanHide() ? 'cursor-not-allowed' : 'cursor-pointer'} items-center gap-2 p-2`}
            >
              <Checkbox
                defaultChecked
                disabled={!column.getCanHide()}
                crossOrigin
                color="indigo"
                ripple={false}
                id={column.id}
                containerProps={{ className: 'p-0' }}
                className="hover:before:content-none"
                checked={column.getIsVisible()}
                onChange={column.getToggleVisibilityHandler()}
              />
              {column.id}
            </label>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
export default ColumnButton;
import UserTable from '../../components/features/user/dashboard/dashboard';

const User = () => (
  <>
    <div className="flex flex-col gap-10 h-full">
      <UserTable />
    </div>
  </>
);

export default User;

import {
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import UserOne from '../../../assets/image/user-01.png';
import EmptyUser from '../../../assets/image/empty-user.jpg';
import {
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { logout as logoutAction } from '../../../redux/auth/actions';
import { selectAuth } from '../../../redux/auth/selectors';
import { Modal } from 'antd';

const DropdownUser = () => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { current } = useSelector(selectAuth);
  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to log out?',
      content: 'This action cannot be undone.',
      onOk() {
        asyncLogout();
        navigate('/login');
      }
    });
  };
  const dispatch = useDispatch();

  function asyncLogout() {
    dispatch<any>(logoutAction());
  }

  return (
    <div className="relative">
      <Link
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4"
        to="#"
      >
        <span className="hidden text-right lg:block">
          <span
            className="block text-sm font-medium text-black dark:text-white"
          >
            {current.userName}
          </span>
          {/* <span className="block text-xs">Admin</span> */}
        </span>

        <span className="h-10 w-10 rounded-full">
          <img
            src={EmptyUser}
            alt="User"
            className='h-full w-full rounded-full border border-stroke object-cover shadow-2'
          />
        </span>

        {dropdownOpen
          ? <ChevronUpIcon className='h-5 w-5' />
          : <ChevronDownIcon className='h-5 w-5' />
        }
      </Link>

      {/* <!-- Dropdown Start --> */}
      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark ${dropdownOpen === true ? 'block' : 'hidden'
        }`}
      >
        <ul
          className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark"
        >
          <li>
            <Link
              to="/profile"
              className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >
              <UserIcon
                className='h-6 w-6'
                strokeWidth={2}
              />
              My Profile
            </Link>
          </li>
          <li>
            <Link
              to="/settings"
              className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
            >
              <Cog8ToothIcon
                className='h-6 w-6'
                strokeWidth={2}
              />
              Account Settings
            </Link>
          </li>
        </ul>
        <button
          className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          onClick={logoutHandler}
        >
          <ArrowLeftOnRectangleIcon className='h-6 w-6' strokeWidth={2} />
          Log Out
        </button>
      </div>
      {/* <!-- Dropdown End --> */}
    </div>
  );
};

export default DropdownUser;

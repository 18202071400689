
import {
  View,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
const MyTable = ({ data = {} }: any) => {
  const styles = StyleSheet.create({
    tableContainer: {
      flexDirection: 'column'
    }
  });

  return <View style={styles.tableContainer}>
    {/* Table 1 */}
    {data?.severity && <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 190
      }}
    >
      <Image
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
        src={data.severity}
      />
    </View>}
    {data?.status && <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 190
      }}
    >
      <Image
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
        src={data.status}
      />
    </View>}
    {data?.easeOfResolution && <View
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 190
      }}
    >
      <Image
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
        src={data.easeOfResolution}
      />
    </View>}
  </View>;
};

export default MyTable;
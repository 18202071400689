export const RESET_STATE = 'PROJECT_RESET_STATE';
export const CURRENT_ITEM = 'PROJECT_CURRENT_ITEM';

export const REQUEST_LOADING = 'PROJECT_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'PROJECT_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'PROJECT_REQUEST_FAILED';

export const CURRENT_ACTION = 'PROJECT_CURRENT_ACTION';
export const RESET_ACTION = 'PROJECT_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_PAGINATION = 'PROJECT_UPDATE_PAGINATION';

export const RESET_LIST = 'PROJECT_RESET_LIST';

export const UPDATE_FILTER = 'PROJECT_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';
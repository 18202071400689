import { AVAILABILITY } from '../../../../../config/enum';
import { SelectFiled } from '../../../../UI/select/Select';

export const criticalityOptions = [{
  value: 'critical',
  label: 'Critical'
}, {
  value: 'high',
  label: 'High'
}, {
  value: 'medium',
  label: 'Medium'
}, {
  value: 'low',
  label: 'Low'
}];

export const dataSensitivityData = [{
  value: 'public',
  label: 'Public'
}, {
  value: 'internal_use',
  label: 'Internal Use'
}, {
  value: 'confidential',
  label: 'Confidential'
}, {
  value: 'secret',
  label: 'Secret'
}];

export const networkExposureData = [{
  value: 'internet',
  label: 'Internet Facing'
}, {
  value: 'internal',
  label: 'Internal Network'

}];
export const complianceData = [{
  value: 'compliant',
  label: 'Compliant'
}, {
  value: 'non_Compliant',
  label: 'Non-Compliant'
}, {
  value: 'not_applicable',
  label: 'Not Applicable'
}];

export const RiskAttributesTab = () => (
  <div className="grid grid-cols-2 gap-5">
    <SelectFiled
      name="criticality"
      label="Criticality"
      placeholder="Select criticality"
      options={criticalityOptions}
    />
    <SelectFiled
      name="data_sensitivity"
      label="Data Sensitivity"
      placeholder="Select data sensitivity"
      options={dataSensitivityData}
    />

    <SelectFiled
      name="network_exposure"
      label="Network Exposure"
      placeholder="Select network exposure"
      options={networkExposureData}
    />
    <SelectFiled
      name="compliance"
      label="Compliance"
      placeholder="Select compliance requirement"
      options={complianceData}
    />
    <SelectFiled
      name="availability"
      label="Availability"
      placeholder="Select availability"
      options={AVAILABILITY}
    />
  </div>
);


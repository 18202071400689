import { createSelector } from 'reselect';

const selectuser = (state) => state.user;

export const selectCurrentItem = createSelector([selectuser], (user) => user.current);

export const selectListItems = createSelector([selectuser], (user) => user.list);

export const visibilityColumnsList = createSelector([selectuser], (user) => user.visibility);

export const selectItemById = (itemId) =>
  createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectuser], (user) => user.create);

export const selectUpdatedItem = createSelector([selectuser], (user) => user.update);

export const selectReadItem = createSelector([selectuser], (user) => user.read);

export const selectDeletedItem = createSelector([selectuser], (user) => user.delete);

export const selectSearchedItems = createSelector([selectuser], (user) => user.search);

export const filter = createSelector([selectuser], (user) => user.filter);
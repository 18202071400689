import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const resources = require('./Locales.js').default;

const defaultLocale = 'en';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['translation', 'common'],
    cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    keySeparator: '.', // don't use keys in form `message.welcome`
    returnObjects: true,
    interpolation: {
      nestingPrefix: '[[',
      nestingSuffix: ']]',
      escapeValue: false, // react already safes from xss
      format: function (value, format) {
        // to uppercase
        if (format === 'uppercase') return value.toUpperCase();

        // to date format
        if (value instanceof Date) {
          return moment(value).format(format);
        }

        // to font style format {{sFontFormat, bolder:#50ef14}} ... some text ... {{eFontStyle}}
        const formatted = formatText(value, format);
        if (formatted) {
          return formatted;
        }

        return value;
      }
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      wait: true,
      bindI18n: 'initialized languageChanged loaded',
      bindI18nStore: 'initialized languageChanged loaded'
    },
    load: defaultLocale,
    partialBundledLanguages: false, // allows some resources to be set on initialization while others can be loaded using a backend connector
    preload: [defaultLocale],
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;

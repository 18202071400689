import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  PieController
} from 'chart.js';

// Register the necessary chart components once
Chart.register(PieController, ArcElement, Tooltip, Legend);

// Function to generate pie chart image URL
const generatePieChartImageURL = async (data: number[], labels: string[], backgroundColor: string[], title?: string): Promise<string> => new Promise((resolve, reject) => {
  // Create a canvas element
  const canvas = document.createElement('canvas');
  canvas.width = 600;
  canvas.height = 600;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    reject(new Error('Failed to get canvas context'));
    return;
  }

  // Create the chart
  const chart = new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels,
      datasets: [{
        data,
        backgroundColor: backgroundColor,
        hoverOffset: 20
      }]
    },
    options: {
      responsive: false,
      animation: {
        animateRotate: false,
        animateScale: false
      },
      plugins: {
        legend: {
          display: true,
          title: {
            display: true,
            text: title,
            position: 'start',
            font: {
              size: 25,
              weight: 'bold'
            }
          },
          position: 'right',
          labels: {
            font: {
              size: 20
            },
            generateLabels: (chart) => {
              if (!chart.data.labels || !chart.data.datasets) {
                return []; 
              }
              const labels = chart.data.labels;
              const datasets : any = chart.data.datasets;
        
              if (datasets.length === 0 || !datasets[0].data) {
                return [];
              }
              const total = datasets[0].data.reduce((acc: any, val: any) => acc + val, 0);
        
              return labels.map((label, i) => {
                const value : any = datasets[0].data[i];
                const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : '0.00';
                return {
                  text: `${label}: ${percentage}%`,
                  fillStyle: datasets[0].backgroundColor ? datasets[0].backgroundColor[i] : '#FFF',
                  fontSize: 20
                };
              });
            }
          }
        },
        tooltip: {
          enabled: false
        }
      }
    }
  });
  
  setTimeout(() => {
    const dataUrl = canvas.toDataURL();
    chart.destroy();
    resolve(dataUrl);
  }, 300); // Adjust delay as needed to ensure the chart has finished rendering
});

export { generatePieChartImageURL };

import { API_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import { AxiosError } from 'axios';

const getList = async ({ page, items, sorting, globalFilter, filter }: { page: number; items: number; sorting: string; globalFilter: string; filter?: any }) => {
  try {

    let url = `${API_BASE_URL}projects/list?page=${page}&total=${items}`;
    if (sorting) {
      url += `&sorting=${JSON.stringify(sorting)}`;
    }
    if (globalFilter) {
      url += `&q=${globalFilter}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    
    const response = await api().get(url);

    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

const create = async (dataAsset: any) => {
  try {
    const response = await api().post(`${API_BASE_URL}project/create`, dataAsset);
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

const deleteById = async (vulnerabilityIds: string[]) => {
  try {
    const response = await api().post(`${API_BASE_URL}projects/delete`, {
      ids: vulnerabilityIds
    });
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

const detailById = async (id: string) => {
  try {
    const response = await api().get(`${API_BASE_URL}projects/${id}/details`);
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

const dashboard = async (id: string) => {
  try {
    const response = await api().get(`${API_BASE_URL}projects/${id}/dashboard`);
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const update = async (id: string, data: any) => {
  try {
    delete data.id;
    delete data.tags;

    const response = await api().put(`${API_BASE_URL}projects/${id}/edit`, data);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getListName = async () => {
  try {
    const response = await api().get(`${API_BASE_URL}projects/name`);
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);

  }
};
export default { 
  create, 
  getList, 
  deleteById, 
  detailById, 
  update, 
  dashboard, 
  getListName
};
import { Button } from '@material-tailwind/react';
import { ButtonProps } from './Types';
import LoadingSmall from '../loading/LoadingSmall';
const ButtonUI: React.FC<ButtonProps> = ({
  name,
  onClick,
  disabled = false,
  type = 'button',
  size = 'md',
  fullWidth = false,
  loading = false,
  className
}) => (
  <Button
    onClick={onClick}
    type={type}
    size={size}
    className={className}
    fullWidth={fullWidth}
    disabled={disabled}
  >
    <div className="flex items-center justify-center">

      {loading ? <div className="mr-2">
        <LoadingSmall />
      </div> : <div/> }
      {name}
    </div>
  </Button>
);

export default ButtonUI;

import {
  ColumnSizingState,
  PaginationState,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Card, Button } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { UserConfig } from './table.config';
import { TableCustom } from '../../../UI/table/table';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectListItems
} from '../../../../redux/users/selectors';
import { users } from '../../../../redux/users/actions';
import { deleteUsers } from '../../../../service/user.service';
import CreateUser from '../tab/CreatUser';
import EditUser from '../tab/EditUser';
/* import { user } from '../../../../redux/user/actions';*/

function UserTable() {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState<SortingState>([]);
  const { result: listResult, isLoading: listIsLoading } = useSelector(selectListItems);
  const [globalFilter, setGlobalFilter] = useState(listResult.search || '');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [pagination, setPaginationState] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 25
  });

  const table = useReactTable({
    data: listResult.items,
    columns: UserConfig.columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    manualSorting: true,
    getRowId: (row: any) => row.id,
    state: {
      sorting,
      pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    pageCount: listResult.pagination.totalPages,
    onPaginationChange: setPaginationState,
    debugAll: true
  });

  const fetchData = () => {
    dispatch<any>(users.list({
      options: {
        page: pagination.pageIndex + 1,
        items: pagination.pageSize,
        sorting,
        globalFilter,
        search: globalFilter
      }
    }));
  };

  useEffect(() => {
    fetchData();
  }, [pagination, sorting, globalFilter]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUserCreated = () => {
    // Fetch data again after user is created
    fetchData();
  };

  return (
    <div>

      <Card>
        <TableCustom
          id='use'
          hidePagination
          table={table}
          showDrawer={false}
          isLoading={listIsLoading}
          totalRecords={listResult.pagination.totalRecords}
          quickSearch={{
            onSearchChange: (value: string) => setGlobalFilter(value),
            onClear: () => setGlobalFilter(''),
            value: globalFilter
          }}
          editAction={(row: any) => {
            setEditModalVisible(true);
            setSelectedUserId(row.id);
          }}
          deleteAction={async (selectedRowIds: string[]) => {
            await deleteUsers(selectedRowIds);
            fetchData();
          }}
          AddButton={
            <Button
              variant="filled"
              className="bg-primary flex items-center"
              size="sm"
              onClick={showModal}
            >
              <PlusIcon
                strokeWidth={4}
                className="h-4 w-4 mr-4"
              /> Add User
            </Button>
          }
        />
      </Card>
      <CreateUser
        isVisible={isModalOpen}
        onClose={handleCancel}
        onUserCreated={handleUserCreated}
      />
      <EditUser
        isVisible={editModalVisible}
        onClose={() => {
          setEditModalVisible(false);
          setSelectedUserId(null);
        }}
        onUserUpdated={fetchData}
        userId={selectedUserId!}
      />
    </div>
  );
}
export default UserTable;
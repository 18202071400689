import {
  Typography,
  CardBody,
  CardHeader,
  IconButton
} from '@material-tailwind/react';
import { Table, flexRender } from '@tanstack/react-table';
import LoadingTable from '../loading/LoadingTable';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import ColumnButton from './components/columnButton/ColumnButton';
import SearchInput from './components/SearchInput';
import {
  Button,
  Checkbox,
  Empty,
  Popconfirm,
  Result
} from 'antd';
import { useNavigate } from 'react-router-dom';
import Export from './components/Export';
interface TableCustomProps<T> {
  id: string;
  table: Table<T>;
  showDrawer?: boolean;
  isLoading?: boolean;
  AddButton?: JSX.Element;
  totalRecords?: number;
  hiddenHeader?: boolean;
  quickSearch?: {
    onSearchChange: (value: string) => void;
    onClear: () => void;
    value: string
  }
  hidePagination?: boolean;
  deleteAction?: (ids: string[]) => void;
  exportAction?: (ids: string[], type?: string) => void;
  editAction?: (row: string) => void;
  CallbackFunction?: (selectedRowId: string) => void;
  metadata?: any;
  isSuccess?: boolean,
  filter?: {
    filterForm: JSX.Element;
    FormikRefFilter: any;
    active?: boolean;
  }
}
import { QuestionCircleOutlined } from '@ant-design/icons';
import Filter from './components/Filter';

export function TableCustom<T>({ id, table, isLoading = false, AddButton, totalRecords = 0, hidePagination, hiddenHeader = false, quickSearch, deleteAction, editAction, exportAction, metadata, isSuccess, filter }: TableCustomProps<any>) {
  let navigate = useNavigate();

  const redirectToDetails = (idObj: string) => {
    const url = `${idObj}`;
    navigate(url);
  };

  const confirm = async () => {
    deleteAction && await deleteAction(table.getSelectedRowModel().flatRows.map((row) => row.id));
    table.setRowSelection({});
  };
  return (
    <div>
      <CardHeader
        hidden={hiddenHeader}
        floated={false}
        shadow={false}
        className="rounded-none"
      >
        <div
          className="flex flex-col items-center justify-between gap-4 md:flex-row"
        >
          <div className='flex items-center gap-2 justify-center'>
            <div>
              {AddButton}
            </div>
            {table.getSelectedRowModel().flatRows.length > 0 && (
              <div className='flex items-center gap-2'>
                {deleteAction &&
                  <Popconfirm
                    title={`Delete ${table.getSelectedRowModel().flatRows.length} ${id.toLocaleLowerCase()}?`}
                    description={`Are you sure to delete ${table.getSelectedRowModel().flatRows.length} items?`}
                    onConfirm={confirm}
                    okText="Delete"
                    okType='danger'
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  >

                    <Button
                      type='text'
                      icon={<TrashIcon
                        height={20}
                        width={20}
                        className="cursor-pointer text-primary"
                      />}
                    ></Button>
                  </Popconfirm>}

                {editAction && table.getSelectedRowModel().flatRows.length === 1 &&
                  <IconButton
                    variant='text'
                    onClick={() => {
                      editAction(table.getSelectedRowModel().flatRows[0].original);
                    }}
                  >
                    <PencilIcon
                      height={20}
                      width={20}
                      className="cursor-pointer text-primary"
                    />
                  </IconButton>}
                {table.getSelectedRowModel().flatRows.length === 1 ?
                  <Button
                    type='text'
                    icon={<EyeIcon
                      height={20}
                      width={20}
                      className="cursor-pointer text-primary"
                    />}
                    onClick={() => redirectToDetails(table.getSelectedRowModel().flatRows[0].id)}
                    // onClick={() => {
                    //   const selectedRowId = table.getSelectedRowModel().flatRows[0].id;
                    //   if (typeof yourCallbackFunction === 'function') {
                    //     yourCallbackFunction(selectedRowId);
                    //   } else {
                    //     redirectToDetails(selectedRowId);
                    //   }
                    // }}

                  ></Button> : <div />}
                <div className='text-blue-gray'>
                  {table.getSelectedRowModel().rows.length} Selected
                </div>
              </div>
            )}
            {metadata}
          </div>
          <div
            className='flex md:flex-row items-center justify-center align-center gap-2 min-w-[140px] '
          >
            <SearchInput
              value={quickSearch?.value}
              onSearch={(value) => quickSearch?.onSearchChange(value)}
            />
            <div
              className={`${hidePagination ? 'hidden' : 'block'} flex gap-2`}
            >
              <Typography
                color="gray"
                className="font-normal"
                hidden={hiddenHeader}
              >
                <div className='text-gray-900 font-medium'>
                  {table.getState().pagination.pageIndex + 1}-{table.getPageCount()} of {totalRecords}
                </div>
              </Typography>
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="border px-1 rounded w-16 "
              />
            </div>
            <div
              className={`bg-gray flex flex-nowrap rounded-full ${hidePagination ? 'hidden' : 'block'}`}
            >
              <IconButton
                size="md"
                variant="text"
                className="rounded-full"
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              >
                <ChevronDoubleLeftIcon
                  strokeWidth={2}
                  className="h-5 w-5"
                />
              </IconButton>
              <IconButton
                size="md"
                variant="text"
                className="rounded-full"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <IconButton
                size="md"
                variant="text"
                className="rounded-full"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <ArrowRightIcon
                  strokeWidth={2}
                  className="h-4 w-4"
                />
              </IconButton>
              <IconButton
                size="md"
                variant="text"
                className="rounded-full"
                disabled={!table.getCanNextPage()}
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              >
                <ChevronDoubleRightIcon
                  strokeWidth={2}
                  className="h-5 w-5"
                />
              </IconButton>
            </div>

            {exportAction && <Export
              onClickExport={(format: string) => {
                exportAction(table.getSelectedRowModel().flatRows.map((row) => row.id), format);
              }}
            />}
            {filter && <Filter
              Form={filter.filterForm}
              Ref={filter.FormikRefFilter}
              enable={filter.active}
            />}
            {/* Show/Hide columns */}
            <ColumnButton
              getIsAllColumnsVisible={table.getIsAllColumnsVisible}
              getToggleAllColumnsVisibilityHandler={table.getToggleAllColumnsVisibilityHandler}
              table={table.getAllLeafColumns()}
              onColumnVisibilityChange={(col) => {}}
            />
            <div className={`${hidePagination ? 'hidden' : 'block'}`}>
              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </ CardHeader>

      <CardBody
        className="px-0 pt-0 overflow-x-auto h-[calc(100vh-15rem)] overflow-y-scroll"
      >
        {/* Show empty data  */}
        {!isLoading && table.getRowModel().rows.length === 0 && (
          <div className='mt-20'>
            {isSuccess === false ? <Result
              status="error"
              title="Submission Failed"
              subTitle="Please try again later."
            /> : <Empty />
            }
          </div>
        )}

        {<table
          className="mt-4 w-full min-w-max table-auto text-left"
        >
          {table.getRowModel().rows.length > 0 && (
            <thead
              className="bg-gray-50 text-normal uppercase tracking-wider sticky top-[-1px] whitespace-nowrap z-10 boder-b border-blue-gray-100 border"
            >
              {/* Render checkbox here */}
              <th className='px-4 max-w-[20px] bg-[#f6f7f8]'>
                {/* Render "select all" checkbox here */}
                <Checkbox
                  checked={table.getIsAllRowsSelected()}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={table.getToggleAllRowsSelectedHandler()}
                />
              </th>
              {table.getFlatHeaders().map((header) => (
                <th
                  key={header.id}
                  className="border-y border-blue-gray-100 bg-[#f6f7f8] px-4 py-2 hover:bg-gray"
                  colSpan={header.colSpan}
                  style={{
                    position: 'relative',
                    width: `${header.getSize()}px`
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-xs font-normal leading-none opacity-70 cursor-pointer flex items-center gap-1"
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    {{
                      asc: <ChevronUpIcon
                        height={15}
                        width={15}
                        strokeWidth={2}
                        className='text-black'
                      />,
                      desc: <ChevronDownIcon
                        height={15}
                        width={15}
                        strokeWidth={2}
                        className='text-black'
                      />
                    }[header.column.getIsSorted() as string] ?? null}

                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                      ></div>
                    )}
                  </Typography>
                </th>
              ))}
            </thead>
          )}
          {/* Show loading of table skeleton */}
          {isLoading && table.getRowModel().rows.length === 0 && (
            <LoadingTable table={table} repeatCount={5} />
          )}
          {/* Show table */}
          {table.getRowModel().rows.length > 0 && (
            <tbody >
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id + index}
                  className={`border-b border-blue-gray-50 hover:bg-gray-2 ${row.getIsSelected() ? 'bg-gray' : ''}`}
                >
                  <td className='px-4 w-[10px]'>
                    {/* Render checkbox here */}
                    <Checkbox
                      checked={row.getIsSelected()}
                      disabled={!row.getCanSelect()}
                      onChange={row.getToggleSelectedHandler()}
                    />
                  </td>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: cell.column.getSize() !== 0
                          ? `${cell.column.getSize()}px`
                          : undefined
                      }}
                      className={'px-6 py-1'}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Typography>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>}
      </CardBody>
    </div>
  );
}


import { NavLink } from 'react-router-dom';
import LogoImg from '../../../assets/image/logoSeeWard.png';
import LogoImgWhite from '../../../assets/image/logoSeeWardWhite.png';
const Logo = () => (
  <NavLink to='/'>
    <img
      src={LogoImgWhite}
      alt='logo'
    />
  </NavLink>
);

export default Logo;

import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import routes from '../../../routes';

const Breadcrumb = React.memo(() => {
  const { pathname } = useLocation();
  const isRootPath = pathname === '/';
  const pathSegments = isRootPath ? [] : pathname.split('/').filter(Boolean);

  const findRouteTitle = (segment: string, index: number, fullPath: string): any => {
    const matchedRoute = routes.find(route => {
      const [basePath] = route.path.split('/:');
      return route.path === fullPath || (fullPath.startsWith(basePath) && route.path.includes('/:'));
    });

    // Determine the title to use for the route
    const isDynamicSegment = matchedRoute && matchedRoute.path.includes('/:') && index === pathSegments.length - 1;
    return {
      ...matchedRoute,
      title: isDynamicSegment ? segment : matchedRoute?.title,
      breadcrumb: segment,
      titleRoute: matchedRoute?.title || segment
    };
  };

  if (isRootPath) return null;

  const lastSegmentRoute = findRouteTitle(pathSegments[pathSegments.length - 1], pathSegments.length - 1, pathname);

  return (
    <nav>
      <div 
        className="mb-2 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between"
      >
        <h2 className="text-title-md font-semibold text-black dark:text-white">
          {lastSegmentRoute.titleRoute}
        </h2>

        <nav>
          <ol className="flex items-center gap-2">
            <li>
              <Link to="/" className='text-primary'>Home /</Link>
            </li>
            {pathSegments.map((segment, index) => {
              const routeTo = `/${pathSegments.slice(0, index + 1).join('/')}`;
              const route = findRouteTitle(segment, index, routeTo);

              return (
                <li key={index}>
                  {index === pathSegments.length - 1
                    ? <span>{route.breadcrumb}</span>
                    : <Link to={routeTo} className='text-primary'>
                      {route.title} /
                    </Link>
                  }
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    </nav>
  );
});

export default Breadcrumb;

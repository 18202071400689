
import LogoImg from '../../../assets/image/logoSeeWard.png';
import LogoImgMin from '../../../assets/image/blue.png';
import LogoWithImg from '../../../assets/image/logoSeeWardWhite.png';
interface LoginLayoutProps {
  children: React.ReactNode
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => <div>
  <section className="bg-white">
    <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
      <section
        className="relative flex h-32 items-end bg-gray-900 lg:col-span-5 lg:h-full xl:col-span-6"
      >
        <img
          alt=""
          src="https://images.unsplash.com/photo-1617195737496-bc30194e3a19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          className="absolute inset-0 h-full w-full object-cover opacity-90 "
        />

        <div className="hidden lg:relative lg:block lg:p-12">
          <img src={LogoWithImg} className='w-40' />

          <h2
            className="mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl"
          >
            Welcome to SeeWard
          </h2>

          <p className="mt-4 leading-relaxed text-white/90">
            Seeward combines all the asset information, vulnerability data from scanning tools,
            and threat intelligence into one single platform for vulnerability teams 
            to eliminate laborious manual data analysis and accelerate decision-making and prioritization.
          </p>
        </div>
      </section>

      <main
        className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6"
      >
        <div className="max-w-xl lg:max-w-3xl">
          <div className="relative -mt-16 block lg:hidden">
            <a
              className="inline-flex size-16 items-center justify-center rounded-full bg-white text-blue-600 sm:size-20"
              href="#"
            >
              <img src={LogoImgMin} alt="" className="h-15 w-15 p-1.5" />
            </a>

            <h1
              className="mt-2 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl"
            >
              Welcome to SeeWard
            </h1>

            <p className="mt-4 leading-relaxed text-gray-500">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi nam dolorum aliquam,
              quibusdam aperiam voluptatum.
            </p>
          </div>
          <div className="lg:w-[500px]">
            <span className="mb-1.5 block font-medium">
              Start your journey
            </span>
            <h2
              className="mb-9 text-2xl font-bold text-black dark:text-white sm:text-title-xl2"
            >
              Sign In to SeeWard
            </h2>

            {children}
          </div>
        </div>
      </main>
    </div>
  </section>
</div>;

export default LoginLayout;
export const RESET_STATE = 'CVE_RESET_STATE';
export const CURRENT_ITEM = 'CVE_CURRENT_ITEM';

export const REQUEST_LOADING = 'CVE_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'CVE_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'CVE_REQUEST_FAILED';

export const CURRENT_ACTION = 'CVE_CURRENT_ACTION';
export const RESET_ACTION = 'CVE_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const RESET_LIST = 'CVE_RESET_LIST';

export const UPDATE_CURRENT_ITEM_FIELD = 'UPDATE_CURRENT_ITEM_FIELD';

export const UPDATE_FILTER = 'CVE_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';
const CardStat = ({ total = 0, name, icon, isLoading, color, selected = false , onClick }: { total: number; name: string; icon?: any, isLoading: boolean, color?: string, selected?: boolean, onClick?: () => void }) => {
  function formatNumber(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <div
      className={`flex ${onClick ? 'cursor-pointer' : ''}  w-full rounded-lg border border-stroke ${selected ? 'bg-gray' : 'bg-white' } py-1 pl-2 pr-2 shadow-default dark:border-strokedark dark:bg-boxdark border-l-4 ${color ? color : 'border-l-primary' }`}
      onClick={onClick}
    >
      <div 
        className="flex items-center justify-between"
      >
        <div className="flex items-center">
          {/* Icon */}
          <div
            className={`flex h-11 w-11 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 mr-4 ${icon ? 'block' : 'hidden'}`}
          >
            {icon}
          </div>

          {/* Text and total */}
          <div>
            <h4
              className="text-md font-bold text-black dark:text-white"
            >
              {isLoading ?
                <div
                  style={{ position: 'relative' }}
                  className="max-w-full border-gray animate-pulse"
                >
                  <div
                    className="w-full bg-gray rounded-lg h-3 my-2 dark:bg-gray-700"
                  ></div>
                </div>
                : formatNumber(total)}
            </h4>
            <span 
              className="text-sm font-medium whitespace-nowrap	"
            >{name}</span>
          </div>
        </div>
        {/* Percentage */}
      </div>
    </div>
  );
};

export default CardStat;

const common = require('./en/common.json');
const App = require('./en/App.json');
const sidebar = require('./en/sidebar.json');
const breadcrumbs = require('./en/breadcrumbs.json');

export default {
  en: {
    common,
    App,
    sidebar,
    breadcrumbs
  }
};

import { FunnelIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@material-tailwind/react';
import { Badge, Button, Popover } from 'antd';
import { useState } from 'react';

interface FILTER_PROPS {
  Form: JSX.Element;
  Ref: any;
  enable?: boolean;
}
const Filter = ({ Form, Ref, enable }: FILTER_PROPS) => {

  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return <Popover
    placement='bottom'
    arrow={false}
    content={<div>
      {Form}
      <div className="flex justify-end">
        <Button
          type="primary"
          htmlType='submit'
          onClick={() => {
            if (Ref.current) {
              Ref.current.submitForm();
              hide();
            }
          }}
        >Filter</Button>
      </div>
    </div>}
    trigger="click"
    open={open}
    onOpenChange={handleOpenChange}
  >
    <IconButton
      size="md"
      variant="text"
      className="rounded-full"
    >
      <Badge dot={enable}>
        <FunnelIcon
          strokeWidth={2}
          className="h-5 w-5 text-body"
        />
      </Badge>

    </IconButton>
  </Popover>;
};

export default Filter;
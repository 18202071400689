import usersService from '../../service/user.service';
import * as actionTypes from './types';
// import { request } from '@/request';
import { filter } from 'lodash';

export const users = {
  resetState:
    (props = {}) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_STATE
        });
      },
  resetAction:
    ({ actionType }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.RESET_ACTION,
          keyState: actionType,
          payload: null
        });
      },
  currentItem:
    ({ data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: { ...data }
        });
      },
  currentAction:
    ({ actionType, data }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.CURRENT_ACTION,
          keyState: actionType,
          payload: { ...data }
        });
      },
  updateVisibility:
    (visibility) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.UPDATE_VISIBILITY,
          payload: visibility
        });
      },
  updatePagination:
    ({ page, items }) =>
      async (dispatch) => {
        dispatch({
          type: actionTypes.UPDATE_PAGINATION,
          payload: { page, items }
        });
      },
  list:
    ({ options } = {}) =>
      async (dispatch, getState) => {
        const { page = 1, items = 100, sorting, globalFilter } = options || {};
        dispatch({
          type: actionTypes.RESET_LIST
        });

        dispatch({
          type: actionTypes.REQUEST_LOADING,
          keyState: 'list',
          payload: null
        });

        const filter = getState().user.filter;
        let response = await usersService.getList({
          page,
          items,
          sorting,
          globalFilter,
          filter: {}
        });

        if (response.users) {
          const { pagination, stats, users } = response;
          const result = {
            items: users,
            pagination: {
              pageIndex: pagination?.current_page,
              pageSize: getState().user.list.result.pagination.pageSize,
              totalRecords: pagination?.total_records,
              nextPage: pagination?.current_page,
              prevPage: pagination?.prev_page,
              totalPages: pagination?.total_pages
            },
            stats
          };

          // Get current state for comparison
          const currentState = getState().user.list.result.items;

          // Check if the new data is different from the current state
          if (JSON.stringify(currentState) !== JSON.stringify(result)) { // to be removed
            dispatch({
              type: actionTypes.REQUEST_SUCCESS,
              keyState: 'list',
              payload: result
            });
          }

        } else {
          dispatch({
            type: actionTypes.REQUEST_FAILED,
            keyState: 'list',
            payload: null
          });
        }
      },
  fetchusersDetail: (id) => async (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_LOADING, keyState: 'current' });
    try {
      const response = await usersService.detailById(id);
      if (response && response.details) {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: response.details
        });
      } else {
        throw new Error('No data received');
      }
    } catch (error) {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'current',
        payload: error.message || 'Error fetching users details'
      });
    }
  },
  feetchusersDashbaord: (id) => async (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_LOADING, keyState: 'current' });
    try {
      const response = await usersService.dashboard(id);
      if (response && response.result) {
        dispatch({
          type: actionTypes.CURRENT_ITEM,
          payload: response.result
        });
      } else {
        throw new Error('No data received');
      }
    } catch (error) {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        keyState: 'current',
        payload: error.message || 'Error fetching vulnerability details'
      });
    }
  },
  updateFilter: (filterValues) => async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FILTER,
      payload: filterValues
    });
  },
  setFilterChangedFlag: (filterHasChanged) => async (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_FILTER_CHANGED_FLAG,
      payload: filterHasChanged
    });
    const { search, pagination } = getState().users.list.result;
    const { pageIndex, pageSize } = pagination;

    dispatch(users.list({
      options: {
        page: pageIndex,
        items: pageSize,
        search,
        filter
      }
    }));
  }
};

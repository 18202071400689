import {
  Button,
  Flex,
  Radio,
  Select,
  Space,
  Tooltip,
  Form as AntdForm,
  Checkbox,
  GetProp,
  Collapse,
  CollapseProps,
  Divider,
  Input,
  Spin,
  Upload,
  Alert
} from 'antd';
import { BlobProvider, pdf } from '@react-pdf/renderer';
import Template1 from './Template1';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import {
  useCallback,
  useEffect,
  useState
} from 'react';
import { VULNERABILITY_STATUS } from '../../../../config/enum';
import projectService from '../../../../service/project.service';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import { report } from '../../../../redux/report/actions';
import { reportItems } from '../../../../redux/report/selectors';
import CardZeus from '../../../UI/card/Card.ui';
import { debounce } from 'lodash';
import { PlusOutlined } from '@ant-design/icons';

const ReportCreate = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  const handleUpdateReport = useCallback(debounce((key, value) => {
    dispatch<any>(report.updateReportField(key, value));
  }, 500), []); // 500 ms delay

  const onChangeCheckFilter: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    handleUpdateReportDirect('filter', checkedValues);
  };
  const onChangeCheckTags: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    handleUpdateReportDirect('tags', checkedValues);
  };

  const [projects, setprojects] = useState([]);
  const [loadingPorject, setloadingPorject] = useState(false);
  useEffect(() => {
    const getProjects = async () => {
      setloadingPorject(true);
      const projects = await projectService.getListName();
      setprojects(projects.projects.map((v: any) => ({
        label: v.name + ` (${v.vulns_count})`,
        value: v.id
      })));
      setloadingPorject(false);
    };
    getProjects();
  }, []);
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <div>
        <Paragraph>Summary of methods and procedures used.</Paragraph>
        <TextArea
          rows={4}
          title='Summary'
          onChange={(e) => handleUpdateReport('summary', e.target.value)}
        />
      </div>
    }, {
      key: '2',
      label: 'Recommendations',
      children: <div>
        <Paragraph>Actions recommended to mitigate vulnerabilities found.</Paragraph>
        <TextArea
          rows={4}
          title='Recommendations'
          onChange={(e) => handleUpdateReport('recommendations', e.target.value)}
        />
      </div>
    }, {
      key: '3',
      label: 'Scope',
      children: <div>
        <Paragraph>Scope of analysis taken into consideration. It refers to the set of assets that were evaluated.</Paragraph>
        <TextArea
          rows={4}
          title='Scope'
          onChange={(e) => handleUpdateReport('scope', e.target.value)}
        />
      </div>
    }, {
      key: '4',
      label: 'Objectives',
      children: <div>
        <Paragraph>Objectives of the evaluation. Information expected to be obtained on the scope of analysis.</Paragraph>
        <TextArea
          rows={4}
          title='Objectives'
          onChange={(e) => handleUpdateReport('objectives', e.target.value)}
        />
      </div>
    }, {
      key: '5',
      label: 'Conclusions',
      children: <div>
        <Paragraph>Findings on vulnerabilities and their characteristics.</Paragraph>
        <TextArea
          rows={4}
          title='Conclusions'
          onChange={(e) => handleUpdateReport('conclusions', e.target.value)}
        />
      </div>
    }
  ];

  const [loadingPdfGeneration, setLoadingPdfGeneration] = useState(false);
  const [startGeneration, setStartGeneration] = useState(false);

  useEffect(() => {
    if (startGeneration) {
      downloadPDF();
    }
  }, [startGeneration]);

  const downloadPDF = async () => {
    const blob = await pdf(
      <Template1
        formData={reportData}
        withVulnerabilitiesData={true}
      />).toBlob();

    // const file = new File([blob], 'generatedDocument.pdf', {
    //   type: 'application/pdf',
    //   lastModified: new Date().getTime()
    // });

    // Example: Use the File object for uploading
    // const formData = new FormData();
    // formData.append('json', JSON.stringify({}));
    // formData.append('file', file);

    // try {
    //   const response = await api().put(`${API_BASE_URL}vulns/6137/edit`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     }
    //   });
    // } catch (error) {
    //   console.error('Error uploading file:', error);
    // }
    const url = URL.createObjectURL(blob);

    // Create a link element, use it to download the PDF, and then remove it from the DOM
    const a = document.createElement('a');
    a.href = url;
    a.download = 'my-document.pdf'; // The default download name
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the object URL and removing the link element
    URL.revokeObjectURL(url);
    document.body.removeChild(a);

    setStartGeneration(false);
    setLoadingPdfGeneration(false);
  };

  const getBase64FromBlobUrl = async (blobUrl: any) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  return <div>
    <Spin spinning={loadingPdfGeneration} fullscreen />
    <div>
      <div
        className="w-[100%] px-10 "
      >
        <div className="flex gap-10 gap-y-10">
          <div className="w-3/5 ">
            <div className='flex'>
              <div className='w-full'>
                <Divider orientation='left'>
                  <Title
                    level={4}
                  > Report Settings</Title>
                </Divider>
              </div>
              <div className="flex justify-end my-5">
                <Flex gap="small">
                  <Button type="default" href="/asset">
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType='submit'
                    itemType='submit'
                    disabled={reportData.name === '' || reportData.client_name === '' || reportData.project === null || reportData.project === ''}
                    onClick={() => {
                      setLoadingPdfGeneration(true); // Set the loading state
                      setStartGeneration(true); // Trigger the PDF generation process
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </div>
            </div>
            <CardZeus className='!p-1'>
              <div
                className="px-10  py-5 bg-white"
              >
                <Text strong>
                  Report Name <span className="text-red-500">*</span>
                </Text>
                <Input
                  title='name'
                  placeholder="Name"
                  aria-label='name'
                  onChange={(e) => handleUpdateReport('name', e.target.value)}
                />
                <Text strong>
                  Client Name <span className="text-red-500">*</span>
                </Text>
                <Input
                  title='name'
                  placeholder="clientName"
                  aria-label='name'
                  onChange={(e) => handleUpdateReport('client_name', e.target.value)}
                />
                <Text strong>
                  Project <span className="text-red-500">*</span>
                </Text>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Please select projects..."
                  onChange={(value) => handleUpdateReport('project', value!)}
                  allowClear
                  value={reportData.project}
                  loading={loadingPorject}
                  disabled={loadingPorject}
                  options={projects}
                />
                <Text strong>
                  Add logo to report
                </Text>
                <Upload
                  listType="picture-card"
                  accept='image/*'
                  locale={{
                    previewFile: 'Preview'
                  }}
                  type='select'
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={async ({ fileList: newFileList }) => {
                    if (newFileList.length === 0) {
                      handleUpdateReport('image', null);
                      return;
                    }
                    const newPreviewUrl = URL.createObjectURL(newFileList[0].originFileObj!);
                    const base64String = await getBase64FromBlobUrl(newPreviewUrl);
                    handleUpdateReport('image', base64String);
                  }}
                >
                  {
                    reportData.image ? null :
                      <button
                        style={{ border: 0, background: 'none' }}
                        type="button"
                      >
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </button>

                  }
                </Upload>

                <div className='mt-10'>
                  <Divider plain orientation='left'>
                    <Title
                      level={4}
                      className='text-primary'
                    >Vulnerability List</Title>
                  </Divider>
                </div>
                <div className='text-gray-600'>
                  Filtered list of detected vulnerabilities that will be shown in the report
                </div>
                <Collapse
                  size="small"
                  items={[
                    {
                      key: '1',
                      label: 'Vulnerabilities',
                      children: <div>
                        <div>
                          <Title
                            level={4}
                          >What do you want to inculed?</Title>
                          <Radio.Group
                            onChange={(e) => handleUpdateReportDirect('vuln_filter', e.target.value)}
                            value={reportData.vuln_filter}
                            disabled={reportData.project ? false : true}
                          >
                            <Space direction="vertical">
                              <Radio value={1}>All Vulnerabilities</Radio>
                              <Radio value={2}>Filter by fields</Radio>
                            </Space>
                          </Radio.Group>

                          <AntdForm
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            disabled={reportData.vuln_filter !== 2}
                            style={{ maxWidth: 600 }}
                          >

                            <div className='my-5 flex flex-col'>
                              Filter by assets
                              <Select
                                allowClear
                                onChange={(v) => handleUpdateReport('asset', v)}
                                value={reportData.asset}
                                style={{ width: '100%' }}
                                placeholder="Type and add asset..."
                                options={reportData.asset_list.map((v: any) => ({
                                  label: v.ip,
                                  value: v.ip
                                }))}
                              />
                            </div>

                            <div
                              className='text-gray-500 mt-10 mb-5'
                            >Click on the filter .</div>

                            <Checkbox.Group
                              options={options}
                              className='gap-4 flex'
                              onChange={onChangeCheckFilter}
                            />

                            <div
                              className='text-gray-600 mt-10 mb-5'
                            >Include this tags .</div>

                            <Checkbox.Group
                              options={optionTags}
                              className='gap-4 grid grid-cols-3'
                              onChange={onChangeCheckTags}
                            />
                          </AntdForm>
                        </div>
                      </div>
                    }
                  ]}
                />

                <Divider plain orientation='left'>
                  <Title level={3}>
                    Sections
                  </Title>
                </Divider>

                <Collapse accordion items={items} size="small" />

              </div>
            </CardZeus>
          </div>
          <div className="w-full">
            <div
              className="h-[750px] w-5/6 rounded-md"
            >
              {reportData.project ? '' : <Alert
                message="You need to select a project"
                className='my-5'
                type="info"
                showIcon
              />}
              <div className={`${reportData.project ? '' : 'hidden'}`}>
                <BlobProvider
                  document={<Template1
                    formData={reportData}
                    withVulnerabilitiesData={false}
                  />}
                >
                  {({ url, loading, error }) => {
                    if (loading) {
                      return <Spin tip="Loading...">
                        <div
                          className="h-[750px] flex justify-center items-center w-full bg-[#494c4f] rounded-lg"
                        />
                      </Spin>;
                    }
                    if (error) {
                      return <div>Error generating document!</div>;
                    }
                    return <div>
                      <div
                        className="flex justify-between items-center my-5 "
                      >
                        <Alert
                          message={`Report generated with ${reportData.vulnerabilities.length} vulnerabilities and ${reportData.asset_list.length} assets.`}
                          type="info"
                          showIcon
                        />
                        <a
                          href={url!}
                          download={(reportData.name ? reportData.name : 'Report') + '.pdf'}
                        >
                          <Tooltip title="Download preview of the report">
                            <Button
                              type="primary"
                              icon={<ArrowDownTrayIcon
                                className='w-4 h-4'
                              />}
                            >Download</Button>
                          </Tooltip>
                        </a>
                      </div>
                      <iframe
                        src={`${url}#toolbar=0`}
                        style={{
                          width: '100%',
                          height: '800px',
                          borderRadius: '5px'
                        }}
                      />
                    </div>;
                  }}
                </BlobProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  </div >;
};

const options = [
  ...VULNERABILITY_STATUS, {
    label: 'confirmed',
    value: 'Confirmed'
  }, {
    label: 'unconfirmed',
    value: 'Unconfirmed'
  }, {
    label: 'Critical',
    value: 'Critical'
  }, {
    label: 'High',
    value: 'High'
  }, {
    label: 'Medium',
    value: 'Medium'
  }, {
    label: 'Low',
    value: 'Low'
  }, {
    label: 'Informational',
    value: 'Informational'
  }];

const optionTags = [{
  label: 'PATCH-AVAILABLE',
  value: 'PATCH-AVAILABLE'
}, {
  label: 'EXPLOIT-AVAILABLE',
  value: 'EXPLOIT-AVAILABLE'
}];
export default ReportCreate;
